// Arqueo.tsx
import React, { useState, useMemo } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Button, TextField } from '@mui/material';
import { PersonaDatosMezclados, ProductosDatosMezclados } from '../../../interfaces/models';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  control: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
  },
  button: {
    minWidth: '40px',
    minHeight: '40px',
    fontSize: '24px',
  },
  monthYearText: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0 20px',
  },
});

interface Props {
  datosPersonas: PersonaDatosMezclados[];
  datosProductos: ProductosDatosMezclados[];
}

const Arqueo: React.FC<Props> = ({ datosPersonas, datosProductos }) => {
  const [mesSeleccionado, setMesSeleccionado] = useState(new Date().getMonth() + 1);
  const [anioSeleccionado, setAnioSeleccionado] = useState(new Date().getFullYear());
  const nombresMeses = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];
  const classes = useStyles();

  const datosFiltradosProductos = useMemo(() => {
    return datosProductos.map(producto => ({
      ...producto,
      ventas: producto.ventas.filter(venta => {
        const ventaDate = new Date(venta.createdAt!);
        return ventaDate.getMonth() + 1 === mesSeleccionado && ventaDate.getFullYear() === anioSeleccionado;
      })
    }));
  }, [datosProductos, mesSeleccionado, anioSeleccionado]);

  const datosFiltradosPersonas = useMemo(() => {
    return datosPersonas.map(persona => ({
      ...persona,
      asistencias: persona.asistencias.filter(asistencia => {
        const asistenciaDate = new Date(asistencia.createdAt!);
        return asistenciaDate.getMonth() + 1 === mesSeleccionado && asistenciaDate.getFullYear() === anioSeleccionado;
      }),
      ventas: persona.ventas.filter(venta => {
        const ventaDate = new Date(venta.createdAt!);
        return ventaDate.getMonth() + 1 === mesSeleccionado && ventaDate.getFullYear() === anioSeleccionado;
      })
    }));
  }, [datosPersonas, mesSeleccionado, anioSeleccionado]);

  const totalVentasProductos = datosFiltradosProductos
    .filter(producto => producto.type === 'producto')
    .reduce((total, producto) => total + producto.ventas.reduce((sum, venta) => sum + (venta.quantity ?? 0) * (producto.salesPrice! - producto.purchasePrice!), 0), 0);

  const totalVentasServicios = datosFiltradosProductos
    .filter(producto => producto.type === 'servicio')
    .reduce((total, producto) => total + producto.ventas.reduce((sum, venta) => sum + (venta.quantity ?? 0) * (producto.salesPrice! - producto.purchasePrice!), 0), 0);

  const totalSueldos = datosFiltradosPersonas.reduce((total, persona) => {
    const totalDiasTrabajados = persona.asistencias.filter(asistencia => asistencia.pagado === true).length;
    return total + (totalDiasTrabajados / persona.diasTrabajar) * persona.salario;
  }, 0);

  const totalComisiones = datosFiltradosPersonas.reduce((total, persona) => {
    return total + persona.ventas.reduce((sum, venta) => sum + (venta.valorComisionAPagar ?? 0), 0);
  }, 0);

  const utilidadReal = totalVentasProductos + totalVentasServicios - totalSueldos - totalComisiones;

  const handleNextMonth = () => {
    if (mesSeleccionado === 12) {
      setMesSeleccionado(1);
      setAnioSeleccionado(prev => prev + 1);
    } else {
      setMesSeleccionado(prev => prev + 1);
    }
  };

  const handlePreviousMonth = () => {
    if (mesSeleccionado === 1) {
      setMesSeleccionado(12);
      setAnioSeleccionado(prev => prev - 1);
    } else {
      setMesSeleccionado(prev => prev - 1);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container justifyContent="center" alignItems="center" className={classes.control} spacing={2}>
        <Grid item>
          <Button className={classes.button} onClick={handlePreviousMonth}>&lt;</Button>
        </Grid>
        <Grid item>
          <Typography className={classes.monthYearText}>
            {`${nombresMeses[mesSeleccionado - 1]} ${anioSeleccionado}`}
          </Typography>
        </Grid>
        <Grid item>
          <Button className={classes.button} onClick={handleNextMonth}>&gt;</Button>
        </Grid>
      </Grid>

      <Typography variant="h6" align="center">Resumen Mensual</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Descripción</TableCell>
              <TableCell align="right">Monto (Bs)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Utilidad Venta de Productos</TableCell>
              <TableCell align="right">{totalVentasProductos.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Utilidad Servicios Realizados</TableCell>
              <TableCell align="right">{totalVentasServicios.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Comisiones Pagadas</TableCell>
              <TableCell align="right">{totalComisiones.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sueldos Pagados</TableCell>
              <TableCell align="right">{totalSueldos.toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Utilidad Real</TableCell>
              <TableCell align="right">{utilidadReal.toFixed(2)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Arqueo;
