import React from 'react';
import './AdminPage.css';
import MainLayoutAdmin from '../../general/MainLayoutAdmin';

const AdminPage: React.FC = () => {
  return (
      <div className="admin-logo">
        <img src="/logo.png" alt="Logo de Soluciones de Calidad" />
      </div>
  );
};

export default AdminPage;
