import React, { useState } from 'react';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import './Atencion.css';
import MainLayoutAdmin from '../../../general/MainLayoutAdmin';
import Venta from './Venta/Venta';
import ControlOperario from './controlOperario/ControlOperario';

const Atencion: React.FC = () => {
  const [activeSection, setActiveSection] = useState('controlOperario');

  const renderSection = () => {
    switch (activeSection) {
      case 'controlOperario':
        return <ControlOperario />;
      case 'venta':
        return <Venta />;
      default:
        return <ControlOperario />;
    }
  };

  return (
    <Box className="atencion-container agregarOperarioContainer" sx={{ marginTop: 0, padding: 0 }}>
      <ToggleButtonGroup
        color="primary"
        value={activeSection}
        exclusive
        onChange={(event, newSection) => setActiveSection(newSection)}
        aria-label="Vista de atención"
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          padding: 0,  // Elimina el padding para ajustar más los botones
          backgroundColor: 'white',
          '& .MuiToggleButton-root': {
            flex: 1,
            borderRadius: 0,
            border: 'none', // Elimina el borde
            margin: 0,  // Elimina el margen
            padding: '10px 0', // Ajusta el padding interno para alinear correctamente los botones
            height: '48px', // Asegura que todos los botones tengan la misma altura
            '&:not(:last-child)': {
              borderRight: '1px solid #ddd', // Añade un borde derecho solo a los botones que no son el último
            }
          },
          '& .Mui-selected': {
            backgroundColor: '#2196f3',
            color: 'white',
          }
        }}
      >
        <ToggleButton value="controlOperario" aria-label="Control Operario">
          Personal
        </ToggleButton>
        <ToggleButton value="venta" aria-label="Venta">
          Venta
        </ToggleButton>
      </ToggleButtonGroup>
      <Box className="atencion-section" sx={{ marginTop: 0 }}>
        {renderSection()}
      </Box>
    </Box>
  );
};

export default Atencion;
