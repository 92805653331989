import React, { useContext } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Box, Container, Paper, ToggleButtonGroup, ToggleButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InventoryContext from '../contexts/InventoryContext';



interface MainLayoutAdminProps {
  children: React.ReactNode;
  onLogout: () => void;
}
const MainLayoutAdmin: React.FC<MainLayoutAdminProps> = ({ children, onLogout }) => {
  const context = useContext(InventoryContext);
  if (!context) {
    throw new Error('UserList must be used within an InventoryProvider');
  }
  const { rol, usuarioActual } = context;
  console.log("---------rolex:",rol)
  const location = useLocation();
  
  const isActive = (path: string) => location.pathname === path;

  const getTabValue = () => {
    if (location.pathname.includes('/personas')) return 'personas';
    if (location.pathname.includes('/productos')) return 'productos';
    if (location.pathname.includes('/servicios')) return 'servicios';
    return 'reportes'; // Valor por defecto
  };

  const [selectedTab, setSelectedTab] = React.useState(getTabValue());

  React.useEffect(() => {
    const currentTab = getTabValue();
    if (selectedTab !== currentTab) {
      setSelectedTab(currentTab);
    }
  }, [location]);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setSelectedTab(newAlignment);
  };
  console.log("usuario actula xD-------------- ", usuarioActual)
  return (
    <Container maxWidth={false}>
      <Paper elevation={3} sx={{ my: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2 }}>
          <ToggleButtonGroup
            color="primary"
            exclusive
            sx={{
              width: '100%',
              flexDirection: { xs: 'column', sm: 'row' },
              '& .MuiToggleButton-root': {
                flexGrow: 1
              }
            }}
            value={selectedTab}
            onChange={handleAlignment}
          >
            {rol==1 && <ToggleButton value="reportes" component={NavLink} to="/reportes" sx={{ backgroundColor: isActive('/reportes') ? '#2196f3' : '#b3e5fc', color: 'white' }}>
              REPORTES
            </ToggleButton>}
            {rol==1 && <ToggleButton value="administracionTotal" component={NavLink} to="/administracionTotal" sx={{ backgroundColor: isActive('/administracionTotal') ? '#2196f3' : '#b3e5fc', color: 'white' }}>
              ADMINISTRACION
            </ToggleButton>}
            <ToggleButton value="atencion" component={NavLink} to="/atencion" sx={{ backgroundColor: isActive('/atencion') ? '#2196f3' : '#b3e5fc', color: 'white' }}>
              CAJA
            </ToggleButton>
            {/* ...otros botones */}
          </ToggleButtonGroup>
          <Button 
            onClick={onLogout} 
            color="error" 
            variant="contained" 
            sx={{ ml: 2, backgroundColor: '#f44336', minWidth: '40px', height: '40px' }}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Paper>
      <main>{children}</main>
      <Paper elevation={3} sx={{ my: 2 }}>
        <Box textAlign="center">
          {/* Información del pie de página si es necesario */}
          Hola {usuarioActual?.firstName} {usuarioActual?.lastName}.😁 ¡Que tengas un excelente día!
        </Box>
      </Paper>
    </Container>
  );
};

export default MainLayoutAdmin;
