// Asume que tienes una API configurada en tu frontend para manejar las solicitudes HTTP
import axios from 'axios';

export const createCuentaSalones = async (data: { cuenta: string, password: string, idUsuario: number, idSalon: number }) => {
    try {
        // Asegúrate de que el endpoint esté definido
        const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
        console.log("my BE es: 5641535", {backendEndpoint, data})
        if (!backendEndpoint) {
            throw new Error("El endpoint del backend no está definido en las variables de entorno.");
        }

        // Llamada al endpoint de tu API
        const response = await axios.post(`${backendEndpoint}/createCuentaSalones`, data);

        // Retorna la respuesta de la API si es exitosa
        return response.data;
    } catch (error) {
        // Manejo de errores
        console.error("Error creando la cuenta:", error);
        return null; // O puedes lanzar un error aquí dependiendo de tu flujo
    }
};
