// AgregarOperario.tsx
import React, { useState, useEffect, ReactNode, useContext } from 'react';
import {
  Box,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import './AgregarOperario.css'; // Asegúrate de tener este archivo CSS para estilos personalizados
import { UserAttributes } from '../../../../interfaces/models'
import InventoryContext from '../../../../contexts/InventoryContext';
// ... otras importaciones

interface ScrollableBoxProps {
  children: ReactNode; // Puedes usar ReactNode para tipos de hijos en React
}
const ScrollableBox: React.FC<ScrollableBoxProps> = ({ children }) => (
  <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
    {children}
  </div>
);



interface UsuariosTablaProps {
  //usuarios: UserAttributes[];
  onUsuarioSeleccionado: (usuario: UserAttributes) => void;
  usuarioSeleccionado: UserAttributes | null;
  //fetchUsuarios: () => Promise<void>;
  //eliminarUsuario: (id: number) => Promise<void>; // Añade esta línea
}

export const UsuariosTabla: React.FC<UsuariosTablaProps> = ({
  //usuarios,
  onUsuarioSeleccionado,
  usuarioSeleccionado,
  //fetchUsuarios,
  //eliminarUsuario,
}) => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('UserList must be used within an InventoryProvider');
  }
  const { tableData, deleteItem } = context;

  const [filteredUsuarios, setFilteredUsuarios] = useState<UserAttributes[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    console.log("se detecto un cambio")
    if (tableData.usuarios) {
      setFilteredUsuarios(
        searchTerm
          ? tableData.usuarios.filter((usuario: UserAttributes) =>
              usuario.firstName!.toLowerCase().includes(searchTerm.toLowerCase()) ||
              usuario.lastName!.toLowerCase().includes(searchTerm.toLowerCase())
            )
          : tableData.usuarios
      );
    }
  }, [tableData, searchTerm]);


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

 /* const filteredUsuarios = searchTerm
    ? tableData.usuarios.filter((usuario) =>
        usuario.firstName!.toLowerCase().includes(searchTerm.toLowerCase()) ||
        usuario.lastName!.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : tableData.usuarios;
*/
  console.log("table user in user: ", tableData.usuarios)
  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          style={{
            color: `rgb(0, 0, 255)`,
            fontWeight: 'bold' // Aplica negrilla al texto
          }}
        >
          INFORMACION GENERAL
        </Typography>
      </Box>

      <TextField
        label="Buscar Usuario"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={handleSearchChange}
      />

      <TableContainer component={Paper}>
        <ScrollableBox>
        <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold', fontSize: 'larger', textTransform: 'uppercase' }}>
                NOMBRE
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold', fontSize: 'larger', textTransform: 'uppercase' }}>
                CELULAR
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

          <TableBody>
          {filteredUsuarios
            .sort((a, b) => {
              // Convertir las fechas de string a Date y luego a tiempo en milisegundos para comparar
              const timeA = new Date(a.createdAt!).getTime();
              const timeB = new Date(b.createdAt!).getTime();
              return timeB - timeA; // Orden descendente, del más reciente al menos reciente
            })
            .map((usuario) => (
              <TableRow
                key={usuario.idUser}
                hover
                onClick={() => onUsuarioSeleccionado(usuario)}
                sx={{
                  backgroundColor: usuarioSeleccionado?.idUser === usuario.idUser ? '#f0f0f0' : 'transparent',
                  cursor: 'pointer',
                }}
              >
                <TableCell>{`${usuario.firstName} ${usuario.lastName}`}</TableCell>
                <TableCell>{usuario.mobileNumber!}</TableCell>
                <TableCell>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation(); // Evitar que el click se propague y seleccione el usuario
                    deleteItem('usuarios', usuario.idUser!); //eliminarUsuario(usuario.idUser!); // Pasar el ID directamente
                  }}
                >
                  <DeleteIcon style={{ color: 'blue' }} />
                </IconButton>

                </TableCell>
              </TableRow>

            ))}
      </TableBody>
        </Table>
        </ScrollableBox>
      </TableContainer>
    </Box>
  );
};