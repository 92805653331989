// src/App.tsx
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/inicio/HomePage';
import LoginPage from './pages/login/LoginPage';
import AdminPage from './pages/admin/AdminPage';
import Reportes from './pages/admin/Reportes/Reportes';
import Atencion from './pages/admin/Atencion/Atencion';
import Administracion from './pages/admin/Administracion/Administracion';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MainLayoutAdmin from './general/MainLayoutAdmin';
import { SeccionProvider } from './hooks/SeccionContext';
import { InventoryProvider } from './contexts/InventoryContext';
import RegisterPage from './pages/login/RegisterPage';
import { getDetailsFromToken } from './pages/login/authUtils';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0 
      },
      styleOverrides: {
        root: {
          boxShadow: 'none' 
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {  
          fontSize: '0.8rem',
          margin: '8px',
          padding: '6px 12px',
        },
      },
    },
  },
  typography: {
    body1: {
      '@media (max-width:600px)': {
        padding: '0 8px',
      },
    },
  },
});

function App() {
  const [salonId, setSalonId] = useState<number | null>(null);
  const [rol, setRol] = useState<number | null>(null);
  const [idUsuario, setIdUsuario] = useState<number | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const handleLogin = (token: string) => {
    const { idSalon, rol, idUsuario } = getDetailsFromToken(token);

    setSalonId(idSalon);
    setRol(rol);
    setIdUsuario(idUsuario);
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    setLoading(false);
  };

  const handleLogout = () => {
    const userConfirmed = window.confirm("¿Estás seguro de que deseas cerrar sesión?");
    
    if (userConfirmed) {
      localStorage.removeItem('token');
      setSalonId(null);
      setRol(null);
      setIdUsuario(null);
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const { idSalon, rol, idUsuario } = getDetailsFromToken(token);

      setSalonId(idSalon);
      setRol(rol);
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <SeccionProvider>
          {isAuthenticated ? (
            <InventoryProvider salonId={salonId} rol={rol} idUsuario={idUsuario}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
                <Route path="/register" element={<RegisterPage />} />

                {/* Rutas para rol 1 */}
                {rol === 1 && (
                  <>
                    <Route path="/administracion" element={<MainLayoutAdmin onLogout={handleLogout}><AdminPage /></MainLayoutAdmin>} />
                    <Route path="/reportes" element={<MainLayoutAdmin onLogout={handleLogout}><Reportes /></MainLayoutAdmin>} />
                    <Route path="/administracionTotal" element={<MainLayoutAdmin onLogout={handleLogout}><Administracion /></MainLayoutAdmin>} />
                    <Route path="/atencion" element={<MainLayoutAdmin onLogout={handleLogout}><Atencion /></MainLayoutAdmin>} />
                  </>
                )}

                {/* Rutas para rol 2 */}
                {rol === 2 && (
                  <>
                    <Route path="/atencion" element={<MainLayoutAdmin onLogout={handleLogout}><Atencion /></MainLayoutAdmin>} />
                  </>
                )}

                <Route path="*" element={<Navigate to={rol === 2 ? "/atencion" : "/"} />} />
              </Routes>
            </InventoryProvider>
          ) : (
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          )}
        </SeccionProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
