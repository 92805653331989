// LadoIzquierdoOperario.tsx
import React, { useState, useEffect, ReactNode,  ChangeEvent, FocusEvent, useContext} from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  Alert
} from '@mui/material';
import InventoryContext from '../../../../contexts/InventoryContext';
import { createCuentaSalones } from '../../../login/createAccount';
//InventoryContext

interface Errores {
    firstName: boolean;
    lastName: boolean;
    idCard: boolean;
    mobileNumber: boolean;
    isSalaryChecked: boolean; // No es necesario validar, pero debe estar en la interfaz
    isFixedCommissionChecked: boolean; // Igualmente, solo para completitud
    fixedPayment: boolean;
    paymentFrequency: boolean;
    serviceFixedCommission: boolean;
    isBoxChecked: boolean; // Solo para completitud
    salario: boolean;
    diasTrabajar: boolean;
  }

interface LadoIzquierdoOperarioProps {
  //fetchUsuariosPrincipal: () => Promise<void>;
}

const formatName = (name: string) => {
  return name
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const formatAmount = (amount: string) => {
  const numericValue = parseFloat(amount.replace(',', '.')).toFixed(2);
  return numericValue
  return numericValue.toString().replace('.', ',');
};


const generarCodigoUsuarioTexto = (firstNameUser:string, lastNameUser:string) : string =>{
    const nombresDivididos = firstNameUser.trim().split(/\s+/);
    const apellidosDivididos = lastNameUser.trim().split(/\s+/);

    // Extrae las letras iniciales según las condiciones
    const letrasNombre = nombresDivididos.length === 1
        ? nombresDivididos[0].substring(0, 2) // Si solo hay un nombre, toma las primeras dos letras
        : nombresDivididos.map(nombre => nombre[0]).join(''); // Si hay más de un nombre, toma la primera letra de cada uno

    const letrasApellido = apellidosDivididos.map(apellido => apellido[0]).join(''); // Toma la primera letra de cada palabra del apellido

    // Combina y convierte a mayúsculas
    const codigoUsuario = (letrasNombre + letrasApellido).toUpperCase();
    //setUserLogin(codigoUsuario);
    return codigoUsuario
}

  

export const LadoIzquierdoOperario: React.FC<LadoIzquierdoOperarioProps> = ({
  //fetchUsuariosPrincipal
}) => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('UserList must be used within an InventoryProvider');
  }
  const { tableData, addItem } = context;


  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [idCard, setIdCard] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [isSalaryChecked, setIsSalaryChecked] = useState(false);
  const [fixedPayment, setFixedPayment] = useState('');
  const [paymentFrequency, setPaymentFrequency] = useState('');
  const [isBoxChecked, setIsBoxChecked] = useState(false);
  const [isFixedCommissionChecked, setIsFixedCommissionChecked] = useState(false);
  const [errores, setErrores] = useState<Errores>({
    firstName: false, /// nombre no nulo, solo letras
    lastName: false, // apellino no nulo, solo letras
    idCard: false, // solo numeros no nulo no negativos
    mobileNumber: false, // no nulo, solo numeros, no negativos
    isSalaryChecked: false, // no es necesario controlar
    isFixedCommissionChecked: false, // no es necesario controlar
    fixedPayment: false, // solo si isSalaryChecked es true, entonces tiene que ser un valor numerico que acpete hasta 2 decimales, no negativo
    paymentFrequency: false, // solo si isSalaryChecked es true, entonces tiene que ser un valor numerico entero, no nulo, no negativo
    serviceFixedCommission: false, // este valor y serviceCommissionPercentage, basta con que 1 sea diferente de 0, solo debe admitir numeros no negativos
    isBoxChecked: false, // no es necsario controlar
    salario: false,
    diasTrabajar: false,
  });
  // Aquí se añadirán más estados si es necesario

  const validarCampos = () => {
    let nuevosErrores: Errores = {
      firstName: false,
      lastName: false,
      idCard: false,
      mobileNumber: false,
      isSalaryChecked: false,
      isFixedCommissionChecked: false,
      fixedPayment: false,
      paymentFrequency: false,
      serviceFixedCommission: false,
      isBoxChecked: false,
      salario: false,
      diasTrabajar: false,
    };
    // Validación para el nombre: no nulo y solo letras
    if (!firstName.trim() || !/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+$/.test(firstName)) {
      nuevosErrores.firstName = true;
    }

    // Validación para el apellido: no nulo y solo letras
    if (!lastName.trim() || !/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+$/.test(lastName)) {
      nuevosErrores.lastName = true;
    }
      
    // Validación para la cédula de identidad: solo números, no nulo, no negativos
    if (!idCard.trim() || !/^\d+$/.test(idCard)) {
      nuevosErrores.idCard = true;
    }
  
    // Validación para el número móvil: no nulo, solo números, no negativos
    if (!mobileNumber.trim() || !/^\d+$/.test(mobileNumber)) {
      nuevosErrores.mobileNumber = true;
    }
  
    // Validación para el monto fijo (solo si isSalaryChecked es true)
    if (isSalaryChecked) {
      const parsedFrequency = parseInt(paymentFrequency, 10);
      // Validación para la frecuencia de pago (solo si isSalaryChecked es true)
      if (!Number.isInteger(parsedFrequency) || parsedFrequency < 0) {
        nuevosErrores.paymentFrequency = true;
      }
      if(!Number(fixedPayment)) {
        nuevosErrores.fixedPayment = true;
      }
    }

      
    setErrores(nuevosErrores);
    console.log("errores: ",errores)
    // Devuelve true si no hay errores
    return !Object.values(nuevosErrores).some(errores => errores);

  };


  useEffect(() => {
    //fetchUsuarios();
  }, []);
        // Función para manejar la selección de un usuario


    const handleBoxCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsBoxChecked(event.target.checked);
    };



    const handleFixedCommissionCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsFixedCommissionChecked(event.target.checked);
    };


  const [operacionExitosa, setOperacionExitosa] = useState<null | boolean>(null);
 
  const [mensaje, setMensaje] = useState('');
    const crearUsuario = async () => {
        try {
            
            if(!validarCampos()){
                console.log("error al validar campos")
                return ;
            }
            // Validación de campos requeridos
            if (!firstName || !lastName || !idCard || !mobileNumber) {
                console.log("Error, todos los campos son requeridos.");
                setOperacionExitosa(false);
                setMensaje('Error, todos los campos son requeridos.');
                return;
            }
    
            //verifica la existencia de un usuario con el mismo ci
            const usuarioExistente = tableData.usuarios.find(usuario => usuario.idCard === idCard);
            if (usuarioExistente) {
                // Usuario ya existe, retornar mensaje de error
                console.log("Error, usuario ya existe");
                setOperacionExitosa(false);
                setMensaje('Error, usuario con mismo CI ya existe.');
                return ;
            }
            // Confirmación antes de proceder TODO mejorar apariencia y cambiar por algo con MUI
            const confirmarCreacion = window.confirm("Listo para agregar al usuario, ¿está seguro de crear nuevo usuario?");
            if (!confirmarCreacion) {
                // Si el usuario presiona "No", detener la ejecución
                return;
            }
            const numeroAleatorio: number = Math.floor(Math.random() * (999 - 100 + 1)) + 100;
            const userLoginLocal = generarCodigoUsuarioTexto(firstName, lastName)+numeroAleatorio.toString();
            // Primera llamada para crear el usuario
            //const responseUser = await usuariosCreate({
            const dataUserCreate = {
              role: (isBoxChecked ? 1 : 2),
                firstName,
                lastName,
                idCard,
                mobileNumber,
                salario: Number(fixedPayment.replace(',', '.')),
                diasTrabajar: Number(paymentFrequency),
                comision: isFixedCommissionChecked,
                // Asegúrate de ajustar este valor según tu lógica
            };
            const responseUser = await addItem('usuarios', dataUserCreate);
            console.log(responseUser);
            if (responseUser.status) {
              setOperacionExitosa(true);
              const userId = responseUser.answer.userId; // Asume que la respuesta incluye el userId del usuario recién creado
              console.log("responseUser: ", responseUser)
              console.log("userId: ", userId)
              if (isBoxChecked) {
                  const responseUserCreate = await createCuentaSalones({
                    cuenta: idCard,
                    password: userLoginLocal,
                    idUsuario: userId,
                    idSalon: 0
                  });
                  if (responseUserCreate) {
                    setMensaje(`Usuario creado con éxito. Credenciales: \nusuario: ${idCard} password: ${userLoginLocal}, GUARDE ESTAS CREDENCIALES!`);
                  } else {
                    setOperacionExitosa(false);
                    setMensaje("Error al crear el usuario, porfavor comuniquese con los administradores.");
                  }
                //setMensaje(`Usuario creados con éxito. Credenciales: \nusuario: ${idCard} password: ${userLoginLocal}`);
              } else {
                setMensaje(`Usuario creados con éxito. No tiene acceso al sistema.`);
              }
              setFirstName('');
              setIsSalaryChecked(false);
              setLastName('');
              setIdCard('');
              setMobileNumber('');
              setFixedPayment('');
              setPaymentFrequency('');
              setIsFixedCommissionChecked(false);
              setIsBoxChecked(false);
            } else {
              console.error('Error al crear el usuario:', responseUser.answer);
              setOperacionExitosa(false);
              setMensaje('Error al crear el usuario.');
            }
    

        } catch (error) {
            console.error('Error al crear el usuario:', error);
            setOperacionExitosa(false);
            setMensaje('Error al crear el usuario.');
        }
    };


    const handleFixedPaymentChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setFixedPayment(value);
  
      // Actualizar el valor de isSalaryChecked basado en el valor de fixedPayment
      if (parseFloat(value) > 0) {
        setIsSalaryChecked(true);
      } else {
        setIsSalaryChecked(false);
      }
    };
    const handleFixedPaymentBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const formattedValue = formatAmount(e.target.value);
      setFixedPayment(formattedValue);
  
      // Validar el valor de fixedPayment
      setErrores((prevErrores) => ({
        ...prevErrores,
        fixedPayment: !formattedValue || isNaN(Number(formattedValue)) || parseFloat(formattedValue) < 0,
      }));
    };
  
    const handleFixedPaymentBlurCombined = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleFixedPaymentBlur(e);
      handleFixedPaymentChange(e as unknown as ChangeEvent<HTMLInputElement>);
    };
  
    const handleSalaryCheck = (e: ChangeEvent<HTMLInputElement>) => {
      setIsSalaryChecked(e.target.checked);
    };
  
  return (
    <Grid item xs={12} md={6}>  
        {/* Fila para Nombre, Apellido Paterno, y Apellido Materno */}
        <Box display="flex" justifyContent="center">
        <Typography
            variant="h6"
            gutterBottom
            component="div"
            style={{
            color: `rgb(0, 0, 255)`,
            fontWeight: 'bold' // Aplica negrilla al texto
            }}
        >
            INGRESAR DATOS
        </Typography>
        </Box>
        <Grid item container xs={12} spacing={3}>
        <Grid item xs={6}>
        <TextField
            fullWidth
            label="Nombre"
            variant="outlined"
            value={firstName}
            onChange={(e) => setFirstName(formatName(e.target.value))}
            error={errores.firstName}
            helperText={errores.firstName ? "El nombre es requerido y solo debe contener letras." : ""}
            required={false}
            //className={classes.customTextField} // Aplica los estilos personalizados
            // No necesitas pasar un objeto de estilos en línea aquí porque ya está definido en useStyles
        />

        </Grid>
        <Grid item xs={6}>
            <TextField className="blackInput" fullWidth label="Apellidos" variant="outlined" required={false} value={lastName} onChange={(e) => setLastName(formatName(e.target.value))} 
                error={errores.lastName}
                helperText={errores.lastName ? "El apellido es requerido y solo debe contener letras." : ""}    
            />
        </Grid>
        </Grid>

        {/* Fila para CI y Celular */}
        <Grid item container xs={12} spacing={3}>
        <Grid item xs={6}>
            <TextField className="blackInput" fullWidth label="C. I." variant="outlined" required={false} value={idCard} onChange={(e) => setIdCard(e.target.value)} 
            error={errores.idCard}
            helperText={errores.idCard ? "El CI es requerido y solo debe contener numeros." : ""}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField className="blackInput" fullWidth label="Celular" variant="outlined" required={false} value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} 
                error={errores.mobileNumber}
                helperText={errores.mobileNumber ? "Elnumero de celular es requerido y solo debe contener numeros." : ""}  
            />
        </Grid>
        </Grid>




      {/* Fila para Monto, Frecuencia de Pago, Tipo de Comisión de Servicio y Comisión de Servicio */}
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={6}>
          <TextField
            className="blackInput"
            fullWidth
            label="Monto"
            variant="outlined"
            required={false}
            value={fixedPayment}
            onChange={handleFixedPaymentChange}
            onBlur={handleFixedPaymentBlurCombined} // Usar la función combinada aquí
            error={errores.fixedPayment}
            helperText={errores.fixedPayment ? "El monto es requerido, debe ser un número no negativo." : ""}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            freeSolo
            options={["10", "20", "30"]} // Tus opciones aquí
            value={paymentFrequency}
            onChange={(event, newValue) => {
              setPaymentFrequency(newValue ?? '');
            }}
            inputValue={paymentFrequency}
            onInputChange={(event, newInputValue) => {
              setPaymentFrequency(newInputValue ?? '');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Frecuencia de Pago [dias]"
                variant="outlined"
                className="blackInput"
                fullWidth
                error={errores.paymentFrequency}
                helperText={errores.paymentFrequency ? "La frecuencia de pago es requerida y solo debe contener números enteros no negativos." : ""}
                disabled={!isSalaryChecked} // Habilitar o deshabilitar basado en isSalaryChecked
              />
            )}
          />
        </Grid>
      </Grid>

        {/* Fila para Caja, Venta % y su input */}
        <Grid container justifyContent="center">
        <Grid item xs={6}>
            <Box display="flex" justifyContent="center">
            <FormControlLabel
                control={<Checkbox checked={isBoxChecked} onChange={handleBoxCheck} />}
                label="Caja"
                sx={{ display: 'flex', justifyContent: 'center' }}
            />
            </Box>
        </Grid>
        <Grid item xs={6}>
            <Box display="flex" justifyContent="center">
                <FormControlLabel
                control={<Checkbox checked={isFixedCommissionChecked} onChange={handleFixedCommissionCheck} />}
                label="Comisión"
                />
            </Box>
            </Grid>
        </Grid>


        {/* Botón Aceptar */}
        <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={crearUsuario}>
            Aceptar
            </Button>
            {operacionExitosa !== null && (
            <Alert severity={operacionExitosa ? "success" : "error"}>
                {mensaje}
            </Alert>
            )}
        </Grid>
    </Grid>
  );
};