import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { usuariosCreate, usuariosReadAll, usuariosDelete, usuariosUpdate } from '../db/user_db';
import { asistenciaCreate, asistenciaReadAll, asistenciaUpdateSalida, asistenciaUpdatePagado } from '../db/asistencia_db';
import { comisionesCreate, comisionesDelete, comisionesReadAll } from '../db/comisiones_db';
import { saleCreate, saleDelete, saleReadAll, saleUpdate } from '../db/sale_db';
import { serviceProductCreate, serviceProductDelete, serviceProductReadAll, serviceProductUpdate } from '../db/serviceProduct_db';
import { ticketCreate, ticketDelete, ticketReadAll, ticketUpdate } from '../db/ticket_db';
import { UserAttributes, AsistenciaAttributes, ComisionInterface, SaleAttributesInterface, ServiceProductAttributesInterface, TicketAttributesInterface } from '../interfaces/models';

interface InventoryContextType {
  tableData: Record<string, any[]>;
  rol: number | null; // Nuevo campo para el rol del usuario
  idUsuario: number | null;
  usuarioActual: UserAttributes | null; // Nuevo campo para almacenar toda la información del usuario actual
  addItem: (table: string, item: any) => Promise<{ status: boolean; answer: any }>;
  updateItem: (table: string, id: number, updatedItem: any) => Promise<{ status: boolean; answer: any }>;
  deleteItem: (table: string, id: number) => Promise<{ status: boolean; answer: any }>;
}

const InventoryContext = createContext<InventoryContextType | undefined>(undefined);

const getId = (table: string, item: any) => {
  switch (table) {
    case 'usuarios':
      return item.idUser;
    case 'tickets':
      return item.idTicket;
    case 'asistencias':
      return item.idAsistencia;
    case 'comisiones':
      return item.idComision;
    case 'sales':
      return item.idSales;
    case 'serviceProducts':
      return item.idServiceProduct;
    default:
      return item.id;
  }
};

export const InventoryProvider = ({ children, salonId, rol, idUsuario }: { children: ReactNode, salonId: number | null, rol: number | null, idUsuario: number | null }) => {
  const [tableData, setTableData] = useState<Record<string, any[]>>({});
  const [loading, setLoading] = useState(true);
  const [usuarioActual, setUsuarioActual] = useState<UserAttributes | null>(null); // Estado para el usuario actual
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      console.log("salod id is : " , salonId)
      if (salonId === null) return;
      try {
        console.log("Fetching data for salonId:", salonId);
        const usuarios = await usuariosReadAll(salonId);
        const usuarioEncontrado = usuarios.find((usuario: UserAttributes) => usuario.idUser === idUsuario);
        if (usuarioEncontrado) {
          setUsuarioActual(usuarioEncontrado); // Guardar la información del usuario actual
        }

        const serviceProducts = await serviceProductReadAll(salonId);
        const comisiones = await comisionesReadAll(salonId);
        const asistencias = await asistenciaReadAll(salonId);//TODO hacer que si es usuario 2, solo lea las asistencias activas y ya
        console.log("mis asistencias son: ", asistencias)
        //AQUI SE ACTIVA ASISTENCIA AUTOMATICA DEL USUARIO ACTUAL
                // Filtrar asistencias del usuario actual
                const asistenciasUsuario = asistencias.filter((asistencia: AsistenciaAttributes) => asistencia.idUser === idUsuario);

                // Verificar si el usuario tiene asistencia activa
                const asistenciaActiva = asistenciasUsuario.find(
                  (asistencia: AsistenciaAttributes) => asistencia.pagado === false && asistencia.salida === false
                );
        
                // Si no hay asistencia activa, crear una nueva
                if (!asistenciaActiva && rol == 2) {
                  console.log("No asistencia activa encontrada, creando nueva asistencia...");
                  const payload: AsistenciaAttributes = {
                    idUser: idUsuario!,
                    notas: "", // Las notas pueden ser vacías
                  };
                  const response = await addItem('asistencias', payload);
                  if (response.status) {
                    asistencias.push(response.answer.data); // Agregar la nueva asistencia a las asistencias
                  }
                }
        
        //const sales = await saleReadAll(salonId);
        //const tickets = await ticketReadAll(salonId);
        
        let sales = [];
        let tickets = [];
        if (rol === 1) { // Solo para rol 1
          sales = await saleReadAll(salonId);
          tickets = await ticketReadAll(salonId);
        }
        setTableData({
          usuarios,
          serviceProducts,
          comisiones,
          asistencias,
          sales,
          tickets,
        });
        console.log("Data fetched successfully");
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error as Error);
        setLoading(false);
      }
    };

    fetchData();
  }, [salonId, rol, idUsuario]);

  const addItem = async (table: string, item: any): Promise<{ status: boolean; answer: any }> => {
    try {
      let newItem: any;
      
      switch (table) {
        case 'usuarios':
          newItem = await usuariosCreate(item as UserAttributes);
          break;
        case 'asistencias':
          newItem = await asistenciaCreate(item as AsistenciaAttributes);
          break;
        case 'comisiones':
          newItem = await comisionesCreate(item as ComisionInterface);
          break;
        case 'sales':
          newItem = await saleCreate(item as SaleAttributesInterface);
          break;
        case 'serviceProducts':
          newItem = await serviceProductCreate(item as ServiceProductAttributesInterface);
          break;
        case 'tickets':
          newItem = await ticketCreate(item as TicketAttributesInterface);
          break;
        default:
          return { status: false, answer: newItem };
      }
      if (newItem) {
        setTableData(prev => ({ ...prev, [table]: [...(prev[table] || []), newItem.data] }));
        return { status: true, answer: newItem };
      }
      return { status: false, answer: 'Item creation failed' };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      console.error(`Error adding item to ${table}:`, errorMessage);
      return { status: false, answer: errorMessage };
    }
  };

  const updateItem = async (table: string, id: number, updatedItem: any): Promise<{ status: boolean; answer: any }> => {
    try {
      let updated: any;
      switch (table) {
        case 'usuarios':
          updated = await usuariosUpdate(updatedItem as UserAttributes);
          break;
        case 'asistencias':
          updated = await asistenciaUpdateSalida(updatedItem as AsistenciaAttributes);
          break;
        case 'asistencias_pagado':
          updated = await asistenciaUpdatePagado(id as number);
          break;
        case 'comisiones':
          // updated = await comisionesUpdate(updatedItem as ComisionInterface);
          break;
        case 'sales':
          updated = await saleUpdate(id as number);
          break;
        case 'serviceProducts':
          updated = await serviceProductUpdate(updatedItem as ServiceProductAttributesInterface);
          break;
        case 'tickets':
          updated = await ticketUpdate(updatedItem as TicketAttributesInterface);
          break;
        default:
          return { status: false, answer: `Unknown table: ${table}` };
      }
      if (updated) {
        const newTable = table === 'asistencias_pagado' ? 'asistencias' : table;
        setTableData(prev => ({
          ...prev,
          [newTable]: prev[newTable].map(item => (getId(newTable, item) === id ? updated.data : item)),
        }));
        return { status: true, answer: updated };
      }
      return { status: false, answer: 'Item update failed' };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      console.error(`Error updating item in ${table}:`, errorMessage);
      return { status: false, answer: errorMessage };
    }
  };

  const deleteItem = async (table: string, id: number): Promise<{ status: boolean; answer: any }> => {
    try {
      let result: any;
      switch (table) {
        case 'usuarios':
          result = await usuariosDelete(id);
          break;
        case 'asistencias':
          // result = await asistenciaDelete(id);
          break;
        case 'comisiones':
          result = await comisionesDelete(id);
          break;
        case 'sales':
          result = await saleDelete(id);
          break;
        case 'serviceProducts':
          result = await serviceProductDelete(id);
          break;
        case 'tickets':
          result = await ticketDelete(id);
          break;
        default:
          return { status: false, answer: `Unknown table: ${table}` };
      }
      if (result) {
        setTableData(prev => ({
          ...prev,
          [table]: prev[table].filter(item => getId(table, item) !== id),
        }));
        return { status: true, answer: 'Item deleted successfully' };
      }
      return { status: false, answer: 'Item deletion failed' };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      console.error(`Error deleting item from ${table}:`, errorMessage);
      return { status: false, answer: errorMessage };
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <InventoryContext.Provider value={{ tableData, rol, idUsuario, usuarioActual, addItem, updateItem, deleteItem }}>
      {children}
    </InventoryContext.Provider>
  );
};

export default InventoryContext;




/*
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { usuariosCreate, usuariosReadAll, usuariosDelete, usuariosUpdate } from '../db/user_db';
import { UserAttributes, AsistenciaAttributes, ComisionInterface, SaleAttributesInterface, ServiceProductAttributesInterface, TicketAttributesInterface } from '../interfaces/models';
import { asistenciaCreate, asistenciaReadAll, asistenciaUpdateSalida, asistenciaUpdatePagado } from '../db/asistencia_db';
import { comisionesCreate, comisionesDelete, comisionesReadAll } from '../db/comisiones_db';
import { saleCreate, saleDelete, saleReadAll, saleUpdate } from '../db/sale_db';
import { serviceProductCreate, serviceProductDelete, serviceProductReadAll, serviceProductUpdate } from '../db/serviceProduct_db';
import { ticketCreate, ticketDelete, ticketReadAll, ticketUpdate } from '../db/ticket_db';

interface InventoryContextType {
  tableData: Record<string, any[]>; // Utiliza Record para manejar diferentes tablas
  addItem: (table: string, item: any) => Promise<{ status: boolean; answer: any }>;
  updateItem: (table: string, id: number, updatedItem: any) => Promise<{ status: boolean; answer: any }>;
  deleteItem: (table: string, id: number) => Promise<{ status: boolean; answer: any }>;
}


const InventoryContext = createContext<InventoryContextType | undefined>(undefined);

const getId = (table: string, item: any) => {
  switch (table) {
    case 'usuarios':
      return item.idUser;
    case 'tickets':
      return item.idTicket;
    case 'asistencias':
      return item.idAsistencia;
    case 'comisiones':
      return item.idComision;
    case 'sales':
      return item.idSales;
    case 'serviceProducts':
      return item.idServiceProduct;
                        // Agrega más casos según sea necesario
    default:
      return item.id;
  }
};

export const InventoryProvider = ({ children }: { children: ReactNode }) => {
  const [tableData, setTableData] = useState<Record<string, any[]>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  console.log("table user: ", tableData)
  // Efecto para cargar los datos al montar el componente
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Llamadas a las funciones para obtener los datos de todas las tablas
        const usuarios = await usuariosReadAll();
        const serviceProducts = await serviceProductReadAll();
        const comisiones = await comisionesReadAll();
        const asistencias = await asistenciaReadAll();
        
        const sales = await saleReadAll();
        const tickets = await ticketReadAll();
        // Actualización del estado con los datos obtenidos
        setTableData({
          usuarios,
          serviceProducts,
          comisiones,
          asistencias,
          
          sales,
          tickets,
        });
        setLoading(false);
      } catch (error) {
        setError(error as Error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const addItem = async (table: string, item: any): Promise<{ status: boolean; answer: any }> => {
    try {
      let newItem: any;
      switch (table) {
        case 'usuarios':
          newItem = await usuariosCreate(item as UserAttributes);
          break;
        case 'asistencias':
          newItem = await asistenciaCreate(item as AsistenciaAttributes);
          break;
        case 'comisiones':
          newItem = await comisionesCreate(item as ComisionInterface);
          break;
        case 'sales':
          newItem = await saleCreate(item as SaleAttributesInterface);
          break;
        case 'serviceProducts':
          newItem = await serviceProductCreate(item as ServiceProductAttributesInterface);
          break;
        case 'tickets':
          newItem = await ticketCreate(item as TicketAttributesInterface);
          break;
        default:
          return { status: false, answer: newItem };
      }
      if (newItem) {
        console.log("antes: ", tableData.usuario)
        setTableData(prev => ({ ...prev, [table]: [...(prev[table] || []), newItem.data] }));
        console.log("despues: ", tableData.usuario)
        return { status: true, answer: newItem };
      }
      return { status: false, answer: 'Item creation failed' };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      console.error(`Error adding item to ${table}:`, errorMessage);
      return { status: false, answer: errorMessage };
    }
  };
  
  
  
  


  const updateItem = async (table: string, id: number, updatedItem: any): Promise<{ status: boolean; answer: any }> => {
    try {
      let updated: any;
      switch (table) {
        case 'usuarios':
          updated = await usuariosUpdate(updatedItem as UserAttributes);
          break;
        case 'asistencias':
          updated = await asistenciaUpdateSalida(updatedItem as AsistenciaAttributes);
          break;
        case 'asistencias_pagado':
          updated = await asistenciaUpdatePagado(id as number);
          break;
        case 'comisiones':
          // updated = await comisionesUpdate(updatedItem as ComisionInterface);
          break;
        case 'sales':
          updated = await saleUpdate(id as number);
          break;
        case 'serviceProducts':
          updated = await serviceProductUpdate(updatedItem as ServiceProductAttributesInterface);
          break;
        case 'tickets':
          updated = await ticketUpdate(updatedItem as TicketAttributesInterface);
          break;
        default:
          return { status: false, answer: `Unknown table: ${table}` };
      }
      if (updated) {
        console.log("data udates", updated)
        console.log("table",{table})
        const newTable = table=='asistencias_pagado'? 'asistencias': table;
        console.log("new table: ", {newTable});
        setTableData(prev => ({
          ...prev,
          [newTable]: prev[newTable].map(item => (getId(newTable, item) === id ? updated.data : item)),
        }));
        return { status: true, answer: updated };
      }
      return { status: false, answer: 'Item update failed' };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      console.error(`Error updating item in ${table}:`, errorMessage);
      return { status: false, answer: errorMessage };
    }
  };

  const deleteItem = async (table: string, id: number): Promise<{ status: boolean; answer: any }> => {
    try {
      let result: any;
      switch (table) {
        case 'usuarios':
          result = await usuariosDelete(id);
          break;
        case 'asistencias':
          // result = await asistenciaDelete(id);
          break;
        case 'comisiones':
          result = await comisionesDelete(id);
          // result = await comisionesDelete(id);
          break;
        case 'sales':
          result = await saleDelete(id);
          break;
        case 'serviceProducts':
          result = await serviceProductDelete(id);
          break;
        case 'tickets':
          result = await ticketDelete(id);
          break;
        default:
          return { status: false, answer: `Unknown table: ${table}` };
      }
      if (result) {
        setTableData(prev => ({
          ...prev,
          [table]: prev[table].filter(item => getId(table, item) !== id),
        }));
        return { status: true, answer: 'Item deleted successfully' };
      }
      return { status: false, answer: 'Item deletion failed' };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      console.error(`Error deleting item from ${table}:`, errorMessage);
      return { status: false, answer: errorMessage };
    }
  };


  // Renderiza un mensaje de carga o error si es necesario
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <InventoryContext.Provider value={{ tableData, addItem, updateItem, deleteItem }}>
      {children}
    </InventoryContext.Provider>
  );
};

export default InventoryContext;


*/