import React, { createContext, useState, useContext, ReactNode } from 'react';

type SeccionContextType = {
  seccionActiva: string;
  setSeccionActiva: (seccion: string) => void;
};

const SeccionContext = createContext<SeccionContextType | undefined>(undefined);

export const useSeccion = (): SeccionContextType => {
  const context = useContext(SeccionContext);
  if (context === undefined) {
    throw new Error('useSeccion must be used within a SeccionProvider');
  }
  return context;
};

type SeccionProviderProps = {
  children: ReactNode;
};

export const SeccionProvider: React.FC<SeccionProviderProps> = ({ children }) => {
  const [seccionActiva, setSeccionActiva] = useState<string>('agregarOperario');

  const value = {
    seccionActiva,
    setSeccionActiva
  };

  return (
    <SeccionContext.Provider value={value}>
      {children}
    </SeccionContext.Provider>
  );
};
