import React, { useState } from 'react';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useSeccion } from '../../../hooks/SeccionContext';
import ControlOperario from '../Atencion/controlOperario/ControlOperario';
import AgregarProducto from './AgregarPS/AgregarProducto';
import AgregarOperario from './AgregarOperario/AgregarOperario';
import SueldosOperario from './Sueldos/SueldosOperario';
import { UserAttributes } from '../../../interfaces/models';

const Administracion: React.FC = () => {
  const { seccionActiva, setSeccionActiva } = useSeccion();
  const [usuarios, setUsuarios] = useState<UserAttributes[]>([]);

  const renderizarSeccion = () => {
    switch (seccionActiva) {
      case 'agregarOperario':
        return <AgregarOperario />;
      case 'agregarpc':
        return <AgregarProducto />;
      case 'sueldos':
        return <SueldosOperario />;
      default:
        return <AgregarOperario />;
    }
  };

  return (
    <Box sx={{ width: '100%', my: 2 }}>
      <ToggleButtonGroup
        color="primary"
        value={seccionActiva}
        exclusive
        onChange={(event, newSeccion) => setSeccionActiva(newSeccion)}
        aria-label="Vista"
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          padding: 0,  // Elimina el padding para ajustar más los botones
          backgroundColor: 'white',
          '& .MuiToggleButton-root': {
            flex: 1,
            borderRadius: 0,
            border: 'none', // Elimina el borde
            margin: 0,  // Elimina el margen
            padding: '10px 0', // Ajusta el padding interno para alinear correctamente los botones
            height: '48px', // Asegura que todos los botones tengan la misma altura
            '&:not(:last-child)': {
              borderRight: '1px solid #ddd', // Añade un borde derecho solo a los botones que no son el último
            }
          },
          '& .Mui-selected': {
            backgroundColor: '#2196f3',
            color: 'white',
          }
        }}
      >
        <ToggleButton value="agregarOperario" aria-label="Agregar Personal">
          Personal
        </ToggleButton>
        <ToggleButton value="agregarpc" aria-label="Control P/S">
          GESTIÓN P/S
        </ToggleButton>
        <ToggleButton value="sueldos" aria-label="Sueldos">
          Sueldos
        </ToggleButton>
      </ToggleButtonGroup>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {renderizarSeccion()}
      </Box>
    </Box>
  );
};

export default Administracion;
