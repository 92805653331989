// Reportes.tsx
import React, { useState, useEffect, useContext } from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Grid } from '@mui/material';

import './Reportes.css';
import axios from 'axios';
import { UserAttributes,
  SaleAttributesInterface,
  AsistenciaAttributes,
  ServiceProductAttributesInterface,
  PersonaDatosMezclados,
  ProductosDatosMezclados,
} from '../../../interfaces/models';
import ComponenteDatosPersonas from './ComponenteDatosPersonas';
import ComponenteDatosProductos from './ComponenteDatosProductos';
import ComponenteDatosServicio from './ComponenteDatosServicio';
import InventoryContext from '../../../contexts/InventoryContext';
import Arqueo from './Arqueo';

function agruparPorFecha<T extends { updatedAt?: Date; payment?: boolean; pagado?: boolean }>(items: T[]): { [key: string]: T[] } {
  return items.reduce((acc, item) => {
    // Filtrar items por payment o pagado
    if (item.payment === true || item.pagado === true) {
      let fecha = item.updatedAt;
      if (!(fecha instanceof Date)) {
        fecha = new Date(item.updatedAt!);
      }
      if (fecha instanceof Date && !isNaN(fecha.getTime())) {
        const fechaISO = fecha.toISOString().split('T')[0]; // Convierte la fecha a formato YYYY-MM-DD
        if (!acc[fechaISO]) {
          acc[fechaISO] = [];
        }
        acc[fechaISO].push(item);
      }
    }
    return acc;
  }, {} as { [key: string]: T[] });
}



function mezclarDatosPersonas(
  usuarios: UserAttributes[],
  asistencias: AsistenciaAttributes[],
  ventas: SaleAttributesInterface[],
  productos: ServiceProductAttributesInterface[]
): PersonaDatosMezclados[] {
  const productosMap = productos.reduce((map, producto) => {
    map[producto.idServiceProduct!] = producto;
    return map;
  }, {} as { [key: number]: ServiceProductAttributesInterface });

  return usuarios.map(usuario => {
    const ventasUsuario = ventas.filter(venta => venta.idUser === usuario.idUser);
    const asistenciasUsuario = asistencias.filter(asistencia => asistencia.idUser === usuario.idUser);

    // Filtrar ventas y asistencias por payment o pagado en true
    const ventasFiltradas = ventasUsuario.filter(venta => venta.payment === true);
    const asistenciasFiltradas = asistenciasUsuario.filter(asistencia => asistencia.pagado === true);

    const ventasAgrupadas = agruparPorFecha(ventasFiltradas);
    const asistenciasAgrupadas = agruparPorFecha(asistenciasFiltradas);
    const productosServiciosPorFecha = Object.keys(ventasAgrupadas).reduce((acc, fecha) => {
      acc[fecha] = ventasAgrupadas[fecha].reduce((totals, venta) => {
        const producto = productosMap[venta.idServiceProduct!];
        if (producto) { // Verifica que el producto exista antes de acceder a sus propiedades
          if (producto.type === 'producto') {
            totals.productos += venta.quantity || 0;
          } else if (producto.type === 'servicio') {
            totals.servicios += venta.quantity || 0;
          }
        } else {
          console.warn(`Producto con ID ${venta.idServiceProduct} no encontrado.`);
        }
        return totals;
      }, { productos: 0, servicios: 0 });
      return acc;
    }, {} as { [key: string]: { productos: number, servicios: number } });

    return {
      idUser: usuario.idUser!,
      firstName: usuario.firstName!,
      lastName: usuario.lastName!,
      idCard: usuario.idCard!,
      mobileNumber: usuario.mobileNumber!,
      salario: usuario.salario!,
      diasTrabajar: usuario.diasTrabajar!,
      comision: usuario.comision!,
      asistencias: asistenciasUsuario,
      ventas: ventasUsuario,
      ventasAgrupadas,
      asistenciasAgrupadas,
      productosServiciosPorFecha,
      role: usuario.role!
    };
  });
}






function mezclarDatosProductos(
  productos: ServiceProductAttributesInterface[],
  ventas: SaleAttributesInterface[]
): ProductosDatosMezclados[] {
  return productos.map(producto => {
    const ventasProducto = ventas.filter(venta => venta.idServiceProduct === producto.idServiceProduct);

    const utilidadPorDia: { [key: string]: number } = {};

    ventasProducto.forEach(venta => {
      const fecha = new Date(venta.createdAt!).toISOString().split('T')[0];  // Asegúrate de que la fecha no tiene problemas de zona horaria
      const utilidadPorVenta = (venta.quantity ?? 0) * (producto.salesPrice! - producto.purchasePrice!);
      if (utilidadPorDia[fecha]) {
        utilidadPorDia[fecha] += utilidadPorVenta;
      } else {
        utilidadPorDia[fecha] = utilidadPorVenta;
      }
    });

    const fechas = Object.keys(utilidadPorDia).sort();
    let sumaAcumulativa = 0;
    const utilidadAcumulativa = fechas.map(fecha => {
      sumaAcumulativa += utilidadPorDia[fecha];
      return {
        fecha,
        utilidadAcumulativa: sumaAcumulativa
      };
    });

    return {
      ...producto,
      ventas: ventasProducto,
      utilidadAcumulativa
    };
  });
}


const Reportes: React.FC = () => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('Reportes must be used within an InventoryProvider');
  }
  const { tableData } = context;

  const [datosPersonasGraficos, setDatosPersonasGraficos] = useState<PersonaDatosMezclados[]>([]);
  const [datosProductosGraficos, setDatosProductosGraficos] = useState<ProductosDatosMezclados[]>([]);
  
  const [vistaSeleccionada, setVistaSeleccionada] = useState('personas');

  const handleVista = (event: React.MouseEvent<HTMLElement>, nuevaVista: string) => {
    setVistaSeleccionada(nuevaVista);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = tableData.usuarios;
        const asistencias = tableData.asistencias;
        const ventas = tableData.sales;
        const productos = tableData.serviceProducts;

        const nuevoResumenPersonas = mezclarDatosPersonas(users, asistencias, ventas, productos);
        const nuevoResumenProductos = mezclarDatosProductos(productos, ventas);
        setDatosPersonasGraficos(nuevoResumenPersonas);
        setDatosProductosGraficos(nuevoResumenProductos);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [tableData]);

  return (
    <>
      <Grid container spacing={0}> {/* Elimina el espacio entre los Grid items */}
      <Grid item xs={12}>
      <ToggleButtonGroup
        color="primary"
        value={vistaSeleccionada}
        exclusive
        onChange={handleVista}
        aria-label="Vista"
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          padding: 0,  // Elimina el padding para ajustar más los botones
          backgroundColor: 'white',
          '& .MuiToggleButton-root': {
            flex: 1,
            borderRadius: 0,
            border: 'none', // Elimina el borde
            margin: 0,  // Elimina el margen
            padding: '10px 0', // Ajusta el padding interno para alinear correctamente los botones
            height: '48px', // Asegura que todos los botones tengan la misma altura
            '&:not(:last-child)': {
              borderRight: '1px solid #ddd', // Añade un borde derecho solo a los botones que no son el último
            },
          },
          '& .Mui-selected': {
            backgroundColor: '#2196f3', // Fondo azul para los botones seleccionados
            color: 'white',
          },
          // Estilo especial para el botón "Arqueo"
          '& .MuiToggleButton-root[value="arqueo"]': {
            backgroundColor: '#FF4500', // Naranja fuego
            color: 'white',
            '&.Mui-selected': {
              backgroundColor: '#FF7F50', // Naranja más claro cuando está seleccionado
              color: 'white',
            },
          },
        }}
      >
        <ToggleButton value="personas" aria-label="Personas">Personal</ToggleButton>
        <ToggleButton value="productos" aria-label="Productos">Productos</ToggleButton>
        <ToggleButton value="servicios" aria-label="Servicios">Servicios</ToggleButton>
        <ToggleButton value="arqueo" aria-label="Arqueo">Arqueo</ToggleButton>
      </ToggleButtonGroup>
    </Grid>

        <Grid item xs={12}>
          <Box sx={{ marginTop: 0, padding: '0 16px' }}>
            {vistaSeleccionada === 'personas' && (
              <ComponenteDatosPersonas datos={datosPersonasGraficos} />
            )}
            {vistaSeleccionada === 'productos' && (
              <ComponenteDatosProductos datos={datosProductosGraficos.filter(p => p.type === 'producto')} />
            )}
            {vistaSeleccionada === 'servicios' && (
              <ComponenteDatosServicio datos={datosProductosGraficos.filter(p => p.type === 'servicio')} />
            )}
            {vistaSeleccionada === 'arqueo' && (
              <Arqueo datosPersonas={datosPersonasGraficos} datosProductos={datosProductosGraficos} />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Reportes;