import jsPDFInvoiceTemplate, { OutputType } from 'jspdf-invoice-template';

// Asegúrate de importar correctamente `UserAttributes`
import { UserAttributes } from '../interfaces/models';

interface Ticket {
  user_id_sale_made: number;
  id_service_product: number;
  oper: string;
  descripcion: string;
  cantidad: number;
  precioUnitario: number;
  tipoComision: number;
  comisionActiva: boolean;
  costoComisionAPagar: number;
  cantidadEnvase: string;
}

export const generateFormattedTicket = (
  usuarios: UserAttributes[],
  tickets: Ticket[],
  calculateGrandTotalValue: number,
  observaciones: string
) => {
  // Formatear la fecha y la hora
  const currentDateTime = new Date().toLocaleString();

  const props = {
    outputType: OutputType.Save,
    returnJsPDFDocObject: true,
    fileName: "Ticket_Venta",
    orientationLandscape: false,
    business: {
      name: "Nombre del Negocio",
      address: "Dirección del negocio",
      phone: "WhatsApp: +1234567890", // Número de WhatsApp en lugar del teléfono
      email: "", // Eliminamos el correo
      website: "", // Eliminamos la página web
    },
    invoice: {
      label: "Ticket #: ",
      invDate: `Fecha y Hora: ${currentDateTime}`, // Mostrar fecha y hora
      headerBorder: false,
      tableBodyBorder: false,
      header: [
        { title: "Descripción", style: { width: 80 } }, // Mover Descripción al primer lugar
        { title: "Pre. U.", style: { width: 30 } },     // Mover Precio Unitario al segundo lugar
        { title: "Cant.", style: { width: 20 } },       // Mover Cantidad al tercer lugar
        { title: "Pre. T.", style: { width: 30 } },     // Precio Total en el cuarto lugar
      ],
      table: tickets.map(ticket => [
        ticket.descripcion,
        ticket.precioUnitario.toFixed(2),
        ticket.cantidad.toString(),
        (ticket.cantidad * ticket.precioUnitario).toFixed(2),
      ]),
      additionalRows: [
        { col1: 'Total:', col2: `${calculateGrandTotalValue}`, col3: 'Bs', style: { fontSize: 12 } },
      ],
      invDescLabel: "Observaciones:",
      invDesc: observaciones,
    },
    footer: {
      text: "Gracias por su compra!",
    },
    pageEnable: true,
    pageLabel: "Página ",
  };

  jsPDFInvoiceTemplate(props);
};
