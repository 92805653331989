import React, { useState, useMemo, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, Select, MenuItem, Button, Grid } from '@mui/material';
import { ProductosDatosMezclados } from '../../../interfaces/models';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar } from 'recharts';
import { TooltipProps } from 'recharts';
import { makeStyles } from '@mui/styles';

interface CustomTooltipProps extends TooltipProps<number, string> {
    // Puedes añadir más props personalizadas si es necesario
}

const useStyles = makeStyles({
    customLabel: {
      '&.MuiInputLabel-shrink': {
        transform: 'translate(20px, -6px) scale(0.75)',
      }
    },
    card: {
      backgroundColor: '#008000',
      color: 'white',
      padding: '20px',
      borderRadius: '8px',
      marginBottom: '20px',
      textAlign: 'center',
      minWidth: '150px',
      maxWidth: '200px',
    },
    card2: {
        backgroundColor: '#002855',
        color: 'white',
        padding: '20px',
        borderRadius: '8px',
        marginBottom: '20px',
        textAlign: 'center',
        minWidth: '150px',
        maxWidth: '200px',
      },
    highlightedCard: {
      backgroundColor: '#002855',
      color: 'white',
      padding: '20px',
      borderRadius: '8px',
      marginBottom: '20px',
      textAlign: 'center',
      minWidth: '150px',
      maxWidth: '200px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add some shadow for better look
      marginTop: '20px', // Add some space at the top
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      gap: '20px',
    },
    control: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 0',
    },
    button: {
        minWidth: '40px',
        minHeight: '40px',
        fontSize: '24px',
    },
    monthYearText: {
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '0 20px',
    },
});

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
        const utilidad = payload[0].value ? payload[0].value.toFixed(2) : 0;
        return (
            <Paper style={{ padding: 10 }}>
                <Typography variant="caption">{`Fecha: ${label}`}</Typography>
                <Typography>{`Utilidad: ${utilidad}`}</Typography>
            </Paper>
        );
    }

    return null;
};

interface Props {
    datos: ProductosDatosMezclados[];
}

const ComponenteDatosServicio: React.FC<Props> = ({ datos }) => {
    const [filtro, setFiltro] = useState('');
    const [productoSeleccionado, setProductoSeleccionado] = useState<ProductosDatosMezclados | null>(null);

    const [mesSeleccionado, setMesSeleccionado] = useState(new Date().getMonth() + 1);
    const [anioSeleccionado, setAnioSeleccionado] = useState(new Date().getFullYear());
    const meses = Array.from({ length: 12 }, (_, i) => i + 1); // Genera meses del 1 al 12
    const anios = Array.from(new Array(20), (val, index) => new Date().getFullYear() - index);
    const nombresMeses = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    const [servicioMasVentas, setServicioMasVentas] = useState<any>(null);
    const [servicioMayorUtilidad, setServicioMayorUtilidad] = useState<any>(null);


    const classes = useStyles();
    const datosFiltrados = useMemo(() => {
        let filteredData = datos.filter((producto) =>
            producto.detalles?.toLowerCase().includes(filtro.toLowerCase())
        );

        if (productoSeleccionado) {
            filteredData = [productoSeleccionado];
        }

        return filteredData.map(producto => ({
            ...producto,
            ventas: producto.ventas.filter(venta => {
                const ventaDate = new Date(venta.createdAt!);
                return ventaDate.getMonth() + 1 === mesSeleccionado && ventaDate.getFullYear() === anioSeleccionado;
            }),
            utilidadAcumulativa: producto.utilidadAcumulativa.filter(u => {
                const utilidadDate = new Date(u.fecha);
                return utilidadDate.getMonth() + 1 === mesSeleccionado && utilidadDate.getFullYear() === anioSeleccionado;
            })
        }));
    }, [datos, filtro, productoSeleccionado, mesSeleccionado, anioSeleccionado]);

    const seleccionarProducto = (producto: ProductosDatosMezclados) => {
        setProductoSeleccionado(productoSeleccionado?.idServiceProduct === producto.idServiceProduct ? null : producto);
    };

    const consolidarDatosGrafico = (filteredData: ProductosDatosMezclados[]) => {
        const fechas = new Set<string>(); // Define explícitamente el tipo de los elementos del Set
        const utilidadConsolidada: { [fecha: string]: { [key: string]: number | string } } = {};

        filteredData.forEach(producto => {
            producto.utilidadAcumulativa.forEach(u => {
                fechas.add(u.fecha);
                if (!utilidadConsolidada[u.fecha]) {
                    utilidadConsolidada[u.fecha] = { fecha: u.fecha };
                }
                utilidadConsolidada[u.fecha][`id${producto.idServiceProduct}`] = u.utilidadAcumulativa;
            });
        });

        return Array.from(fechas).sort().map(fecha => utilidadConsolidada[fecha]);
    };

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    // Método para asignar un color único, asegurándose de que el índice esté dentro del rango de la paleta
    const getColor = (index: number) => colors[index % colors.length];

    const datosGrafico = useMemo(() => {
        if (productoSeleccionado) {
            return productoSeleccionado.utilidadAcumulativa;
        } else {
            return consolidarDatosGrafico(datosFiltrados);
        }
    }, [datosFiltrados, productoSeleccionado]);

    const resumenDatos = datosFiltrados.map(producto => ({
        detalles: producto.detalles,
        trabajosMes: producto.ventas.reduce((acc, venta) => acc + (venta.quantity ?? 0), 0)
    }));

    // Función para acortar los nombres largos
    const shortenLabel = (label: string, maxLength: number) => {
        return label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;
    };
    const { utilidadMensual, comisionAcumulada } = datosFiltrados.reduce((acc, producto) => {
        const utilidad = producto.salesPrice! - producto.purchasePrice!;
        const totalVendidosMes = producto.ventas.reduce((acc, venta) => acc + (venta.quantity ?? 0), 0);
        const valorComisionPagada = producto.ventas.reduce((acc, venta) => acc + (venta.valorComisionAPagar ?? 0), 0);

        acc.utilidadMensual += totalVendidosMes * utilidad;
        acc.comisionAcumulada += valorComisionPagada;

        return acc;
    }, { utilidadMensual: 0, comisionAcumulada: 0 });
    const handleNextMonth = () => {
        if (mesSeleccionado === 12) {
            setMesSeleccionado(1);
            setAnioSeleccionado(prev => prev + 1);
        } else {
            setMesSeleccionado(prev => prev + 1);
        }
    };

    const handlePreviousMonth = () => {
        if (mesSeleccionado === 1) {
            setMesSeleccionado(12);
            setAnioSeleccionado(prev => prev - 1);
        } else {
            setMesSeleccionado(prev => prev - 1);
        }
    };
    useEffect(() => {
        const servicioConMasVentas = datosFiltrados.reduce((max, servicio) => {
            const totalVentas = servicio.ventas.reduce((acc: number, venta: any) => acc + (venta.quantity ?? 0), 0);
            const utilidad = servicio.salesPrice! - servicio.purchasePrice!;
            const valorComisionPagada = servicio.ventas.reduce((acc: number, venta: any) => acc + (venta.valorComisionAPagar ?? 0), 0);
            const utilidadMensual = totalVentas * utilidad - valorComisionPagada;
            return totalVentas > max.totalVentas 
                ? { ...servicio, totalVentas, totalUtilidad: utilidadMensual }
                : max;
        }, { totalVentas: 0, totalUtilidad: 0 });
    
        const servicioConMayorUtilidad = datosFiltrados.reduce((max, servicio) => {
            const utilidad = servicio.salesPrice! - servicio.purchasePrice!;
            const totalVentas = servicio.ventas.reduce((acc: number, venta: any) => acc + (venta.quantity ?? 0), 0);
            const valorComisionPagada = servicio.ventas.reduce((acc: number, venta: any) => acc + (venta.valorComisionAPagar ?? 0), 0);
            const utilidadMensual = totalVentas * utilidad - valorComisionPagada;
            return utilidadMensual > max.totalUtilidad 
                ? { ...servicio, totalVentas, totalUtilidad: utilidadMensual }
                : max;
        }, { totalUtilidad: 0, totalVentas: 0 });
    
        setServicioMasVentas(servicioConMasVentas);
        setServicioMayorUtilidad(servicioConMayorUtilidad);
        console.log({servicioConMasVentas})
        console.log({servicioConMayorUtilidad})
    }, [datosFiltrados]);
    
    
    return (
        <Box sx={{ width: '100%', bgcolor: 'white' }}>
            <TextField
                fullWidth
                label="Buscar"
                variant="outlined"
                onChange={(e) => setFiltro(e.target.value)}
            />
            <Grid container justifyContent="center" alignItems="center" className={classes.control} spacing={2}>
                <Grid item>
                    <Button className={classes.button} onClick={handlePreviousMonth}>&lt;</Button>
                </Grid>
                <Grid item>
                    <Typography className={classes.monthYearText}>
                        {`${nombresMeses[mesSeleccionado - 1]} ${anioSeleccionado}`}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button className={classes.button} onClick={handleNextMonth}>&gt;</Button>
                </Grid>
            </Grid>

            {!productoSeleccionado && (
                <Box sx={{ mt: 2, maxHeight: 500, overflow: 'auto' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                letterSpacing: '2px',
                                fontWeight: 'bold',
                                margin: '20px 0', // Margen superior e inferior
                            }}
                            >
                            Resumen de Servicios
                            </Typography>
                    <BarChart
                        layout="vertical"
                        width={window.innerWidth * 0.9}
                        height={500}
                        data={resumenDatos}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" />
                        <YAxis type="category" dataKey="detalles" tickFormatter={(value) => shortenLabel(value, 10)} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="trabajosMes" fill="#8884d8" name="Trabajos Mes" />
                    </BarChart>
                    <Box className={classes.container}>
                        <Box className={classes.card2}>
                            <Typography variant="h6">Mayor Ventas</Typography>
                            <Typography variant="subtitle2">Servicio: {servicioMasVentas?.detalles}</Typography>
                            <Typography variant="body1">Ventas: {servicioMasVentas?.totalVentas}</Typography>
                            <Typography variant="body1">Utilidad: {(servicioMasVentas?.totalUtilidad ?? 0).toFixed(2)}</Typography>
                        </Box>
                        <Box className={classes.card2}>
                            <Typography variant="h6">Mayor Utilidad</Typography>
                            <Typography variant="subtitle2">Servicio: {servicioMayorUtilidad?.detalles}</Typography>
                            <Typography variant="body1">Ventas: {servicioMayorUtilidad?.totalVentas}</Typography>
                            <Typography variant="body1">Utilidad: {servicioMayorUtilidad?.totalUtilidad.toFixed(2)}</Typography>
                        </Box>
                        <Box className={classes.card}>
                            <Typography variant="h6">{comisionAcumulada.toFixed(2)}</Typography>
                            <Typography variant="subtitle2">COM ACUMULADAS</Typography>
                        </Box>
                        <Box className={classes.card}>
                            <Typography variant="h6">{(utilidadMensual-comisionAcumulada).toFixed(2)}</Typography>
                            <Typography variant="subtitle2">UTILIDAD MES</Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Descripción</TableCell>
                            <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Costo</TableCell>
                            <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Precio</TableCell>
                            <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Utilidad</TableCell>
                            <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>#Trabajos</TableCell>
                            <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>comision total</TableCell>
                            <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Utilidad total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosFiltrados
                            .sort((a, b) => {
                                const totalVentasA = a.ventas.reduce((acc, venta) => acc + (venta.quantity ?? 0), 0);
                                const totalVentasB = b.ventas.reduce((acc, venta) => acc + (venta.quantity ?? 0), 0);
                                return totalVentasB - totalVentasA;
                            })
                            .map((producto) => {
                            const utilidad = producto.salesPrice! - producto.purchasePrice!;
                            const totalVendidosMes = producto.ventas.reduce((acc, venta) => acc + (venta.quantity ?? 0), 0);
                            const valorComisionPagada = producto.ventas.reduce((acc, venta) => acc + (venta.valorComisionAPagar ?? 0), 0)
                            const utilidadMensual = totalVendidosMes * utilidad - valorComisionPagada;

                            return (
                                <TableRow key={producto.idServiceProduct} hover onClick={() => seleccionarProducto(producto)}>
                                    <TableCell>{producto.detalles}</TableCell>
                                    <TableCell>{producto.purchasePrice!.toFixed(2)}</TableCell>
                                    <TableCell>{producto.salesPrice!.toFixed(2)}</TableCell>
                                    <TableCell>{utilidad.toFixed(2)}</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        {totalVendidosMes}
                                    </TableCell>
                                    <TableCell>{valorComisionPagada.toFixed(2)}</TableCell>
                                    <TableCell>
                                        {utilidadMensual.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {productoSeleccionado && (
                <Box sx={{ mt: 2, maxHeight: 500, overflow: 'auto' }}>
                    <Typography variant="h6">Utilidad Acumulativa</Typography>
                    <LineChart
                        width={window.innerWidth * 0.9}
                        height={window.innerHeight * 0.7}
                        data={productoSeleccionado.utilidadAcumulativa}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="fecha" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Line type="monotone" dataKey="utilidadAcumulativa" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                </Box>
            )}
        </Box>
    );
};

export default ComponenteDatosServicio;
