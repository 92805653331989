import React, { useContext } from 'react';
import { TextField, Grid, MenuItem, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InventoryContext from '../../../../contexts/InventoryContext';
import { ComisionInterface } from '../../../../interfaces/models';

interface AgregarProductoIzquierdaComisionProps {
  dataComisionValor: string;
  setDataComisionValor: React.Dispatch<React.SetStateAction<string>>;
  dataComisionTipo: string;
  setDataComisionTipo: React.Dispatch<React.SetStateAction<string>>;
  handleTextFieldChange: (
    setFunction: React.Dispatch<React.SetStateAction<string>>,
    transformFunction?: (value: string) => string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AgregarProductoIzquierdaComision: React.FC<AgregarProductoIzquierdaComisionProps> = ({
  dataComisionValor,
  setDataComisionValor,
  dataComisionTipo,
  setDataComisionTipo,
  handleTextFieldChange
}) => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('UserList must be used within an InventoryProvider');
  }
  const { tableData, deleteItem } = context;

  const handleDelete = (id: number) => {
    deleteItem('comisiones', id);
  };

  console.log("Renderizando componente AgregarProductoIzquierdaComision");
  console.log("Props recibidas:", { dataComisionValor, dataComisionTipo, ...tableData.comisiones });

  return (
    <Grid item xs={12} md={6}>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={5}>
          <TextField
            type="number"
            fullWidth
            label="Valor"
            variant="outlined"
            value={dataComisionValor}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              console.log("Cambio en Valor:", e.target.value);
              handleTextFieldChange(setDataComisionValor)(e);
            }}
            required={false}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            select
            fullWidth
            name="comisionPorcentaje"
            label="Tipo Comision"
            variant="outlined"
            value={dataComisionTipo}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              console.log("Cambio en Tipo Comision:", e.target.value);
              handleTextFieldChange(setDataComisionTipo)(e);
            }}
          >
            {["%", "Bs"].map((tipo, index) => (
              <MenuItem key={index} value={tipo}>
                {tipo}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{ maxHeight: 200, overflow: 'auto', maxWidth: 600 }}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Tipo Comisión</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...tableData.comisiones].reverse().map((comision: ComisionInterface, index: number) => (
              <TableRow key={index}>
                <TableCell align="center" component="th" scope="row">
                  {comision.comision} {comision.tipo}
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => deleteItem('comisiones', comision.idComision!)}>
                    <DeleteIcon style={{ color: 'blue' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default AgregarProductoIzquierdaComision;
