import React, { useState, useEffect, ReactNode, useContext} from 'react';
import {
  Paper,
  Grid,
} from '@mui/material';
import './ControlOperario.css'; // Estilos personalizados
import axios from 'axios';
import { UserAttributes, AsistenciaAttributes } from '../../../../interfaces/models';
import { SeccionIzquierda } from './IzquierdoControlOperario';
import { SeccionDerecha } from './DerechoControlOperario';
import InventoryContext from '../../../../contexts/InventoryContext';

// Inicialización de la API
const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
});









const ControlOperario: React.FC = () => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('UserList must be used within an InventoryProvider');
  }
  const { tableData, deleteItem, addItem, updateItem } = context;


  const [usuarios, setUsuarios] = useState<UserAttributes[]>([]);
  const [asistencias, setAsistencias] = useState<AsistenciaAttributes[]>([]);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState<string>("");
  const [mensaje, setMensaje] = useState("");
  const [operacionExitosa, setOperacionExitosa] = useState<null | boolean>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResponse = tableData.usuarios; //await usuariosReadAll();
        setUsuarios(usersResponse);
        const asistenciaResponse = tableData.asistencias.filter(asistencia => !asistencia.salida); // await asistenciaReadAllActive()
        console.log("asistencias activas actualels: ", asistenciaResponse)
        setAsistencias(asistenciaResponse);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    fetchData();
  }, []);

  const crearAsistencia = async (observaciones: string) => {
    if (!usuarioSeleccionado) {
      alert("Por favor, seleccione un usuario.");
      return;
    }

    try {
      const payload:AsistenciaAttributes = {
        idUser: Number(usuarioSeleccionado),
        notas: observaciones,
      };
      const response = addItem('asistencias',payload); // await asistenciaCreate(payload);
      if(response == null) {
          console.log("error 500"); 
          setMensaje('Error al crear la asistencia');
          setOperacionExitosa(false);
          return;
      }
      //await api.post('/asistenciaCreate', payload);
      setMensaje('Asistencia registrada con éxito.');
      setOperacionExitosa(true);
      // Refetch asistencias data to update UI
      const asistenciaResponse = tableData.asistencias.filter(asistencia => !asistencia.salida);//await asistenciaReadAllActive();
      setAsistencias(asistenciaResponse);
    } catch (error) {
      console.error("Error al crear la asistencia:", error);
    }
  };

  const marcarSalida = async (idAsistencia: number, notaTemporal: string) => {
    try {
        const data: AsistenciaAttributes = {
            idAsistencia,
            notas: notaTemporal,
        };

        const response = await updateItem('asistencias', idAsistencia, data) // await asistenciaUpdateSalida(data);
        if (response) {
            setMensaje('Salida marcada con éxito.');
            setOperacionExitosa(true);
            // Refetch asistencias data to update UI
            const asistenciaResponse = tableData.asistencias.filter(asistencia => !asistencia.salida);//await asistenciaReadAllActive();
            setAsistencias(asistenciaResponse);
        } else {
            setMensaje('Error al marcar la salida');
            setOperacionExitosa(false);
        }
    } catch (error) {
        console.error("Error al marcar la salida:", error);
        setMensaje('Error al marcar la salida');
        setOperacionExitosa(false);
    }
};

  return (
    <Paper className="controlOperarioContainer">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SeccionIzquierda
            usuariosAll={usuarios}
            seleccionarUsuario={setUsuarioSeleccionado}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SeccionDerecha
            usuarioSeleccionado={usuarioSeleccionado}
            usuarios={usuarios}
            asistencias={asistencias}
            //crearAsistencia2={crearAsistencia}
            //marcarSalida2={marcarSalida}
            mensaje2={mensaje}
            operacionExitosa2={operacionExitosa}
            setMensaje={setMensaje}
            setOperacionExitosa={setOperacionExitosa}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ControlOperario;
