import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import QrCodeIcon from '@mui/icons-material/QrCode';
import PrintIcon from '@mui/icons-material/Print';
import { UserAttributes } from '../../../../interfaces/models';
import { generateFormattedTicket } from '../../../../utils/pdfUtils';

interface Ticket {
  user_id_sale_made: number;
  id_service_product: number;
  oper: string;
  descripcion: string;
  cantidad: number;
  precioUnitario: number;
  tipoComision: number;
  comisionActiva: boolean;
  costoComisionAPagar: number;
  cantidadEnvase: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 10px) scale(1)',
      backgroundColor: 'white',
      padding: '0 4px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));

interface VentaCentroProps {
  handleOpenDialog: () => void;
  handleOpenConfirmDialog: () => void;
  openConfirmDialog: boolean;
  handleCloseConfirmDialog: () => void;
  grandTotal: number;
  handleConfirmVenta: () => void;
  openDialog: boolean;
  handleCloseDialog: () => void;
  observaciones: string;
  setObservaciones: (value: string) => void;
  handleSaveObservaciones: () => void;
  tickets: Ticket[];
  usuarios: UserAttributes[];
  calculateGrandTotal: (data: Ticket[]) => number;
}

export const VentaCentro: React.FC<VentaCentroProps> = ({
  handleOpenDialog,
  handleOpenConfirmDialog,
  openConfirmDialog,
  handleCloseConfirmDialog,
  grandTotal,
  handleConfirmVenta,
  openDialog,
  handleCloseDialog,
  observaciones,
  setObservaciones,
  handleSaveObservaciones,
  tickets,
  usuarios,
  calculateGrandTotal,
}) => {
  const [cobrar, setCobrar] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleChangeCobrar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCobrar(event.target.value);
    if (parseFloat(event.target.value) >= grandTotal) {
      setError('');
    }
  };

  const handleValidationAndConfirm = () => {
    if (!cobrar || parseFloat(cobrar) < grandTotal) {
      setError('El monto a cobrar debe ser mayor o igual al total.');
      return;
    }
    handleConfirmVenta();
  };

  const classes = useStyles();

  useEffect(() => {
    if (openConfirmDialog) {
      setCobrar('');
      setError('');
    }
  }, [openConfirmDialog]);

  const cambio = parseFloat(cobrar) - grandTotal;

  return (
    <Grid>
      <Grid item xs={12} md={1}>
        <Grid container direction="column" spacing={1}>
          <Tooltip title="Agregar Observaciones">
            <IconButton color="primary" onClick={handleOpenDialog}>
              <AddCommentIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Pagar en Efectivo">
            <IconButton color="primary" onClick={handleOpenConfirmDialog}>
              <AttachMoneyIcon />
            </IconButton>
          </Tooltip>
          <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog} maxWidth="sm" fullWidth>
            <DialogTitle>Confirmar Venta de Ticket</DialogTitle>
            <Box p={3}>
              <Box mb={2}>
                <TextField
                  label="Pago"
                  value={cobrar}
                  onChange={handleChangeCobrar}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  className={classes.textField}
                  error={!!error}
                  helperText={error}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label="Cobrar"
                  value={grandTotal.toFixed(2)}
                  disabled
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  className={classes.textField}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label="Cambio"
                  value={isNaN(cambio) ? '' : cambio.toFixed(2)}
                  disabled
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  className={classes.textField}
                />
              </Box>
              <DialogActions>
                <Button onClick={handleCloseConfirmDialog} variant="outlined">No</Button>
                <Button onClick={handleValidationAndConfirm} variant="contained" color="primary">Sí</Button>
                <Tooltip title="Imprimir Ticket">
                  <IconButton
                    color="secondary"
                    size="large"
                    onClick={() => generateFormattedTicket(usuarios, tickets, calculateGrandTotal(tickets), observaciones)}
                  >
                    <PrintIcon />
                  </IconButton>
                </Tooltip>
              </DialogActions>
            </Box>
          </Dialog>
          <Tooltip title="Pagar con QR">
            <IconButton color="primary" onClick={handleOpenConfirmDialog}>
              <QrCodeIcon />
            </IconButton>
          </Tooltip>
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Agregar Observaciones</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="observaciones"
                label="Observaciones"
                type="text"
                fullWidth
                variant="outlined"
                value={observaciones}
                className={classes.textField}
                onChange={(e) => setObservaciones(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancelar</Button>
              <Button onClick={handleSaveObservaciones}>Guardar</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Grid>
  );
};
