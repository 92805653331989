// AgregarOperario.tsx
import React, { useState, useEffect, ReactNode, useContext } from 'react';
import {
  Paper,
  Grid,
} from '@mui/material';


import './AgregarOperario.css'; // Asegúrate de tener este archivo CSS para estilos personalizados

import { UsuariosTabla } from './LadoDerechoOperario';
import { LadoIzquierdoOperario } from './LadoIzquierdoOperario';
import { UserAttributes } from '../../../../interfaces/models'
import InventoryContext from '../../../../contexts/InventoryContext';



const AgregarOperario: React.FC = () => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('UserList must be used within an InventoryProvider');
  }
  const { tableData, deleteItem, addItem, updateItem } = context;



  const [usuarios, setUsuarios] = useState<UserAttributes[]>([]);
  const [operacionExitosa, setOperacionExitosa] = useState<null | boolean>(null);
  const [mensaje, setMensaje] = useState('');
  
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState<UserAttributes | null>(null);

  
  // Función para cargar usuarios desde tu backend
  const fetchUsuarios = async () => {
    try {
      const response = tableData.usuarios; //await usuariosReadAll()
      const usuariosMapeados = response.map((user: any) => ({
        codigo: user.idUser.toString(), // Asumiendo que quieres el ID como string
        firstName: user.firstName,
        lastName: user.lastName,
        ci: user.idCard, // Asume que `ci` corresponde a `idCard` en tu modelo
        celular: user.mobileNumber,
        comision: user.comision,
        createdAt: new Date(user.createdAt),
      }));
      setUsuarios(usuariosMapeados);
    } catch (error) {
      console.error('Error al cargar los usuarios:', error);
    }
  };
    
  const handleSelectUsuario = (usuario: UserAttributes) => {
    setUsuarioSeleccionado(usuario);
  };

  const eliminarUsuario = async (userId: number) => {
    if (!userId) {
      console.error("El ID del usuario es undefined.");
      return;
  }
    console.log("usuario selecionado: ",usuarioSeleccionado)
    // Confirmación antes de proceder a eliminar al usuario TODO mejorar apariencia y cambiar por algo con MUI
    const confirmarCreacion = window.confirm("¿está seguro de eliminar al usuario?: "+ usuarioSeleccionado?.firstName);
    if (!confirmarCreacion) {
      // Si el usuario presiona "No", detener la ejecución
      return;
    }
    try {
      deleteItem('usuarios', userId) //await usuariosDelete(Number(userId));
      await fetchUsuarios(); // Actualiza la lista después de la eliminación
      setMensaje('Usuario eliminado correctamente.');
      setOperacionExitosa(true);
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
      setMensaje('Error al eliminar el usuario.');
      setOperacionExitosa(false);
    }
  };

  useEffect(() => {
    //fetchUsuarios();
  }, []);
  return (
    <Paper className="agregarOperarioContainer"> {/* Utiliza la clase para los estilos */}
      

      <Grid container spacing={3}>
        {/* LADO IZQUIERDO*/}
        <LadoIzquierdoOperario
          //fetchUsuariosPrincipal={fetchUsuarios}
        />

        {/*LADO DERECHO*/}
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <UsuariosTabla
            onUsuarioSeleccionado={handleSelectUsuario}
            usuarioSeleccionado={usuarioSeleccionado}
            //fetchUsuarios={fetchUsuarios}
            //eliminarUsuario={eliminarUsuario} // Asegúrate de que esta función exista en tu componente
          />
        </Grid>
        
      </Grid>
    </Paper>
    
  );
};

export default AgregarOperario;
