import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { authenticateUser, getDetailsFromToken } from './authUtils';

interface LoginPageProps {
  onLogin: (token: string) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const token = await authenticateUser(username, password);
    if (token) {
      const salonId = getDetailsFromToken(token);
      onLogin(token);
      navigate('/administracion');
    } else {
      setError('Cuenta o contraseña inválida');
    }
  };

  const handleCancel = () => {
    navigate('/');
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  

  return (
    <div className="login-page" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/FONDO2.png'})` }}>
      <div className="logo-container">
        <img src={process.env.PUBLIC_URL + '/images/logo_nova_2_fuego.png'} alt="NOVA Logo" className="logo" />
        <h3 className="bienvenido-text">BIENVENIDO</h3>
      </div>
      <div className="login-container">
        <form onSubmit={handleSubmit} className="login-form">
          <div
            className="user-box"
            style={{
              backgroundColor: username ? 'rgba(173, 216, 230, 0.3)' : 'transparent', // Fondo celeste suave si hay texto
            }}
          >
            <img src={process.env.PUBLIC_URL + '/images/usuario.png'} alt="User Icon" className="icon" />
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <label>Nombre de Usuario</label>
          </div>
          <div
  className="user-box"
  style={{
    position: 'relative',
    backgroundColor: password ? 'rgba(173, 216, 230, 0.3)' : 'transparent', // Fondo celeste suave si hay texto
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%', // Limita el ancho máximo para evitar que se desborde
  }}
>
  <img src={process.env.PUBLIC_URL + '/images/pass.png'} alt="Key Icon" className="icon" />
  <input
    type={showPassword ? 'text' : 'password'}
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    required
    style={{
      paddingRight: '40px', // Espacio extra para el icono del ojo
      flex: 1, // Para que el input ocupe el espacio disponible sin desbordar
    }}
  />
  <label>Contraseña</label>
  <span
    onClick={toggleShowPassword}
    style={{
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
    }}
  >
    {showPassword ? (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFC107" className="bi bi-eye" viewBox="0 0 16 16">
        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.07-.122.146-.195.23-.267.31-.647.708-1.11 1.12C12.12 10.332 10.12 11.5 8 11.5c-2.12 0-4.121-1.168-5.168-2.457A13.07 13.07 0 0 1 1.173 8z"/>
        <path d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"/>
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFC107" className="bi bi-eye-slash" viewBox="0 0 16 16">
        <path d="M13.359 11.238a13.133 13.133 0 0 0 1.66-2.043c.383-.504.708-1.005.96-1.457A13.07 13.07 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C12.12 4.668 10.12 3.5 8 3.5c-2.12 0-4.121 1.168-5.168 2.457a13.07 13.07 0 0 0-1.66 2.043 13.07 13.07 0 0 0 1.66 2.043c1.047 1.289 3.047 2.457 5.168 2.457 1.104 0 2.178-.289 3.141-.807.231.115.471.216.71.303a12.87 12.87 0 0 0 1.516-.527l1.407 1.407a.5.5 0 0 0 .708-.708l-1.407-1.407a12.87 12.87 0 0 0 .527-1.516.713.713 0 0 0 .02-.065zM4.568 4.568A12.97 12.97 0 0 0 3.05 5.64a12.88 12.88 0 0 0-.96 1.457 12.97 12.97 0 0 0 0 1.707c.252.452.577.953.96 1.457a12.97 12.97 0 0 0 1.518 1.51 12.97 12.97 0 0 0 1.517 1.511 12.97 12.97 0 0 0 1.707 0 12.97 12.97 0 0 0 1.518-1.51l-8.287-8.287zM8 4a4 4 0 1 0 0 8A4 4 0 0 0 8 4zm0 1a3 3 0 1 1 0 6A3 3 0 0 1 8 5z"/>
      </svg>
    )}
  </span>
</div>

          {error && <p className="error">{error}</p>}
          <div className="buttons">
            <button type="submit" className="ingreso-button">INGRESO</button>
          </div>
        </form>
      </div>
      <p className="integral-text">ASESORAMIENTO INTEGRAL</p>
    </div>
  );
};

export default LoginPage;
