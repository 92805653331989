import React, { useState, useEffect, useContext } from 'react';
import { TextField, Grid, InputAdornment, Box, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import PhotoIcon from '@mui/icons-material/Photo';
import InventoryContext from '../../../../contexts/InventoryContext';

interface AgregarProductoIzquierdaServicioProps {
    datoDetalles: string;
    setDatoDetalles: React.Dispatch<React.SetStateAction<string>>;
    datoCostoCompra: number;
    setDatoCostoCompra: React.Dispatch<React.SetStateAction<number>>;
    datoCostoVenta: number;
    setDatoCostoVenta: React.Dispatch<React.SetStateAction<number>>;
    datoImagen: string;
    setDatoImagen: React.Dispatch<React.SetStateAction<string>>;
    handleTextFieldChange: (
        setFunction: React.Dispatch<React.SetStateAction<string>>,
        transformFunction?: (value: string) => string
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleTextFieldBlur: (
        setFunction: React.Dispatch<React.SetStateAction<string>>,
        transformFunction: (value: string) => string
    ) => (event: React.FocusEvent<HTMLInputElement>) => void;
    handleNumberFieldChange: (
        setFunction: React.Dispatch<React.SetStateAction<number>>,
        transformFunction?: (value: string) => number
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleNumberFieldBlur: (
        setFunction: React.Dispatch<React.SetStateAction<number>>,
        transformFunction: (value: string) => number
    ) => (event: React.FocusEvent<HTMLInputElement>) => void;
    formatName: (name: string) => string;
    formatAmount: (amount: string) => number;
    imagesRutas: string[];
    descripcionImagenes: string[];
    errors: { [key: string]: boolean };
}

const AgregarProductoIzquierdaServicio: React.FC<AgregarProductoIzquierdaServicioProps> = ({
    datoDetalles,
    setDatoDetalles,
    datoCostoCompra,
    setDatoCostoCompra,
    datoCostoVenta,
    setDatoCostoVenta,
    datoImagen,
    setDatoImagen,
    handleTextFieldChange,
    handleTextFieldBlur,
    handleNumberFieldChange,
    handleNumberFieldBlur,
    formatName,
    formatAmount,
    imagesRutas,
    descripcionImagenes,
    errors
}) => {
    const context = useContext(InventoryContext);

    if (!context) {
      throw new Error('UserList must be used within an InventoryProvider');
    }
    const { tableData, deleteItem, addItem, updateItem } = context;

    
    const [open, setOpen] = useState(false);
    const [filtro, setFiltro] = useState('');
    const [imagenesFiltradas, setImagenesFiltradas] = useState<string[]>([]);
    const [imagenesFiltradasNombre, setImagenesFiltradasNombre] = useState<string[]>([]);

    useEffect(() => {
        const filtradas = imagesRutas.filter((ruta, index) =>
            descripcionImagenes[index].toLowerCase().includes(filtro.toLowerCase())
        );
        const nombresFiltrados = descripcionImagenes.filter((descripcion) =>
            descripcion.toLowerCase().includes(filtro.toLowerCase())
        );
        setImagenesFiltradas(filtradas);
        setImagenesFiltradasNombre(nombresFiltrados);
    }, [filtro, imagesRutas, descripcionImagenes]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleListItemClick = (imgRuta: string) => {
        setDatoImagen(imgRuta);
        setOpen(false);
    };

    return (
        <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Descripcion de servicio"
                        variant="outlined"
                        value={datoDetalles}
                        onChange={handleTextFieldChange(setDatoDetalles, formatName)}
                        error={errors.datoDetalles}
                        helperText={errors.datoDetalles ? "Este campo es obligatorio" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Costo"
                        variant="outlined"
                        value={datoCostoCompra.toFixed(2)}
                        onChange={handleNumberFieldChange(setDatoCostoCompra, (value) => formatAmount(value))}
                        onBlur={handleNumberFieldBlur(setDatoCostoCompra, (value) => formatAmount(value))}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{ color: 'gray' }}>
                                    Bs
                                </InputAdornment>
                            ),
                        }}
                        error={errors.datoCostoCompra}
                        helperText={errors.datoCostoCompra ? "Este campo es obligatorio" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Precio Venta"
                        variant="outlined"
                        value={datoCostoVenta.toFixed(2)}
                        onChange={handleNumberFieldChange(setDatoCostoVenta, (value) => formatAmount(value))}
                        onBlur={handleNumberFieldBlur(setDatoCostoVenta, (value) => formatAmount(value))}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{ color: 'gray' }}>
                                    Bs
                                </InputAdornment>
                            ),
                        }}
                        error={errors.datoCostoVenta}
                        helperText={errors.datoCostoVenta ? "Este campo es obligatorio" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <IconButton onClick={handleClickOpen} color="primary" aria-label="upload picture" component="span">
                            <PhotoIcon />
                            {datoImagen && <img src={datoImagen} alt="Seleccionada" style={{ width: 50, marginLeft: 10 }} />}
                        </IconButton>
                        <Dialog open={open} onClose={handleClose} fullWidth>
                            <DialogTitle>Seleccionar Imagen</DialogTitle>
                            <DialogContent>
                                <TextField
                                    margin="dense"
                                    id="search"
                                    label="Buscar"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    className="buscar-input"
                                    onChange={(e) => setFiltro(e.target.value)}
                                />
                                <Grid container spacing={2}>
                                    {imagenesFiltradas.map((imgRuta, index) => (
                                        <Grid item xs={6} key={imgRuta}>
                                            <Box onClick={() => handleListItemClick(imgRuta)} role="button" sx={{ textAlign: 'center' }}>
                                                <Typography variant="body2">{imagenesFiltradasNombre[index]}</Typography>
                                                <img src={imgRuta} alt={imagenesFiltradasNombre[index]} style={{ width: '100%', maxWidth: '100px', height: '100px' }} />
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancelar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AgregarProductoIzquierdaServicio;
