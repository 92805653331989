import React from 'react';
import Header from './Header';
import MainBanner from './MainBanner';
import ServicesSection from './ServicesSection';
import ContactSection from './ContactSection';
import AboutUs from './AboutUs';
import useScrollToAnchor from '../../hooks/useScrollToAnchor';
import { Box, Container } from '@mui/material';
import ImageBanner from './ImageBanner';
import Footer from './Footer';

const HomePage: React.FC = () => {
  useScrollToAnchor();

  return (
    <Box className="home-page"> {/* Box para la estructura general */}
      <Header /> {/* Navegación con enlaces a secciones */}
      {/*Banner principal con imagen */}
      {/*<MainBanner />  */}
      <ImageBanner
        imageUrl="images/main/fondo1.png"
        overlayColor="rgba(0, 0, 0, 0)"
      />
      
      {/*<Container maxWidth="lg">  Contenedor centralizado */}
        {/* Sección de Servicios */}
        {/*<Box id="services" sx={{ my: 4 }}> 
          <ServicesSection />
        </Box>*/}
        
        {/* Sección Sobre Nosotros */}  
        {/*<Box id="about-us" sx={{ my: 4 }}> 
          <AboutUs />
        </Box>*/}
        
        {/* Sección de Contacto */}        
        {/*<Box id="contact" sx={{ my: 4 }}> 
          <ContactSection />
        </Box>*/}
      {/*</Container>*/}
      <ImageBanner
        imageUrl="images/main/fondo2.png"
        overlayColor="rgba(0, 0, 0, 0)"
      />
      <ImageBanner
        imageUrl="images/main/fondo3.png"
        overlayColor="rgba(0, 0, 0, 0)"
      />
      <ImageBanner
        imageUrl="images/main/fondo4.png"
        overlayColor="rgba(0, 0, 0, 0)"
      />
      <Footer/>
    </Box>
  );
}

export default HomePage;
