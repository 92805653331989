import React, { useState } from 'react';
import {
  Box, Typography, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Grid, TextField
} from '@mui/material';
import { UserAttributes } from '../../../../interfaces/models';

interface SeccionIzquierdaProps {
  usuariosAll: UserAttributes[];
  seleccionarUsuario: (codigo: string) => void;
}

export const SeccionIzquierda: React.FC<SeccionIzquierdaProps> = ({
  usuariosAll,
  seleccionarUsuario,
}) => {
  const [usuarioActivo, setUsuarioActivo] = useState<string | null>(null);
  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredUsers = usuariosAll.filter(usuario =>
    `${usuario.firstName} ${usuario.lastName}`.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Paper className="paper">
      <Box display="flex" justifyContent="center">
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          style={{ color: '#F59622', fontWeight: 'bold' }}
        >
          ASISTENCIA
        </Typography>
      </Box>

      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          <TextField
            fullWidth
            label="Buscar"
            variant="outlined"
            margin="normal"
            value={searchText}
            onChange={handleSearchChange}
          />
        </Grid>
      </Grid>

      <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <TableContainer sx={{ display: 'flex', justifyContent: 'center' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{ fontWeight: 'bold', fontSize: 'larger', textTransform: 'uppercase' }}
                  >
                    NOMBRE
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={{ fontWeight: 'bold', fontSize: 'larger', textTransform: 'uppercase' }}
                  >
                    CELULAR
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((usuario) => (
                <TableRow
                  key={usuario.idUser!.toString()}
                  onClick={() => {
                    seleccionarUsuario(usuario.idUser!.toString());
                    setUsuarioActivo(usuario.idUser!.toString());
                  }}
                  sx={{
                    '&:hover': { backgroundColor: 'lightgray' },
                    ...(usuarioActivo === usuario.idUser!.toString() && { backgroundColor: 'lightblue' }),
                  }}
                >
                  <TableCell>{`${usuario.firstName} ${usuario.lastName}`}</TableCell>
                  <TableCell>{usuario.mobileNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  );
};
