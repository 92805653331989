
// AgregarProducto.tsx
import React, { useEffect, useState, ReactNode, useContext } from 'react';
import {
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  IconButton,
} from '@mui/material';
import './AgregarProducto.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { ServiceProductAttributesInterface } from '../../../../interfaces/models'
import InventoryContext from '../../../../contexts/InventoryContext';



interface ScrollableBoxProps {
  children: ReactNode; // Puedes usar ReactNode para tipos de hijos en React
}
const ScrollableBox: React.FC<ScrollableBoxProps> = ({ children }) => (
  <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
    {children}
  </div>
);







// Tipos de propiedades para AgregarProductoDerecha
interface AgregarProductoDerechaProps {
    //productos: ServiceProductAttributesInterface[];
    onProductoSeleccionado: (producto: ServiceProductAttributesInterface) => void;
    productoSeleccionado: ServiceProductAttributesInterface | null;
    //handleDeleteProduct: (id:number) => void; 
  }
  // Componente para la tabla de productos o servicios
 export const AgregarProductoDerecha: React.FC<AgregarProductoDerechaProps> = ({
    //productos,
    onProductoSeleccionado,
    productoSeleccionado,
    //handleDeleteProduct,
  }) => {
    const context = useContext(InventoryContext);

    if (!context) {
      throw new Error('UserList must be used within an InventoryProvider');
    }
    const { tableData, deleteItem, addItem, updateItem } = context;


      // Estado para manejar el término de búsqueda
    const [searchTerm, setSearchTerm] = useState('');
    console.log("producto selecioando: ",productoSeleccionado)
  
    // Función para manejar cambios en el campo de búsqueda
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    };
  
    // Filtrar los datos de la tabla basado en searchTerm
    const filteredData = searchTerm
      ? tableData.serviceProducts.filter((item) =>
          item.detalles!.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : tableData.serviceProducts;
      const colorAzulRGB = '0, 0, 255'; // Azul en formato RGB
    const eliminarSProducto = async(id:number)=>{
      deleteItem('serviceProducts', id);
    }
      return (
        <Box>
        <Box display="flex" justifyContent="center">
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            style={{
              color: `rgb(${colorAzulRGB})`,
              fontWeight: 'bold' // Aplica negrilla al texto
            }}
          >
            PRODUCTOS Y SERVICIOS EN GENERAL
          </Typography>
        </Box>
  
          <TextField
            label="Buscar por Tipo"
            variant="outlined"
            fullWidth
            margin="normal"
            className="buscar-input" // Asegúrate de aplicar la clase aquí
            onChange={handleSearchChange}
          />
          <ScrollableBox>
            <TableContainer component={Paper}>
              <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell>
                    <Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold', fontSize: 'larger', textTransform: 'uppercase' }}>
                      TIPO
                    </Typography>
                  </TableCell>*/}
                  <TableCell>
                    <Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold', fontSize: 'larger', textTransform: 'uppercase' }}>
                      DESCRIPCION
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold', fontSize: 'larger', textTransform: 'uppercase' }}>
                      MARCA
                    </Typography>
                  </TableCell>
                  {/*<TableCell>
                    <Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold', fontSize: 'larger', textTransform: 'uppercase' }}>
                      CANTIDAD
                    </Typography>
                </TableCell>*/}
                  <TableCell>
                    <Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold', fontSize: 'larger', textTransform: 'uppercase' }}>
                      PRECIO (BS)
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1" component="div" style={{ fontWeight: 'bold', fontSize: 'larger', textTransform: 'uppercase' }}>
                      STOCK
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
  
                <TableBody>
                  {filteredData
                    .sort((a, b) => {
                      // Convertir las fechas de string a Date y luego a tiempo en milisegundos para comparar
                      const timeA = new Date(a.createdAt!).getTime();
                      const timeB = new Date(b.createdAt!).getTime();
                      return timeB - timeA; // Orden descendente, del más reciente al menos reciente
                    })
                    .map((producto) => (
                    <TableRow
                      key={producto.idServiceProduct}
                      onClick={() => onProductoSeleccionado(producto)}
                      style={{ backgroundColor: productoSeleccionado?.idServiceProduct === producto.idServiceProduct ? '#f0f0f0' : '' }}
                    >
                      {/*<TableCell>{producto.name}</TableCell>*/}
                      <TableCell>{producto.detalles}</TableCell>
                      <TableCell>{producto.marca}</TableCell>
                      {/*<TableCell>{producto.cantidadEnvase}</TableCell>*/}
                      <TableCell>{producto.salesPrice.toFixed(2)}</TableCell>
                      <TableCell>{producto.quantity}</TableCell>
                      <TableCell>
                      <IconButton
                        onClick={() => eliminarSProducto(producto.idServiceProduct!)}>
                        <DeleteIcon style={{ color: 'blue' }} />
                      </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
  
              </Table>
            </TableContainer>
          </ScrollableBox>
        </Box>
      );
  };
  

  
