import React, { useEffect, useState, useContext } from 'react';
import { Box, Button, Grid } from '@mui/material';
import './AgregarProducto.css';
import { AgregarProductoDerecha } from './AgregarProductoDerecha';
import { ServiceProductAttributesInterface } from '../../../../interfaces/models';
import AgregarProductoIzquierda from './AgregarProductoIzquierda';
import InventoryContext from '../../../../contexts/InventoryContext';

interface Comision {
  comision: number;
  tipo: string;
}

const AgregarProducto: React.FC = () => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('UserList must be used within an InventoryProvider');
  }
  const { tableData, deleteItem, addItem, updateItem } = context;

  const [productos, setProductos] = useState<ServiceProductAttributesInterface[]>([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState<ServiceProductAttributesInterface | null>(null);
  const [estaEditando, setEstaEditando] = useState(false);
  const [comisiones, setComisiones] = useState<Comision[]>([]);

  const fetchProductos = async () => {
    try {
      const response = tableData.serviceProducts;
      setProductos(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchComisiones = async () => {
    try {
      const response = tableData.comisiones;
      setComisiones(response);
    } catch (error) {
      console.error("Error al obtener las comisiones:", error);
    }
  };

  const handleSelectProducto = (producto: ServiceProductAttributesInterface) => {
    setProductoSeleccionado(producto);
    setEstaEditando(true);
  };

  const handleDeselectProducto = () => {
    setProductoSeleccionado(null);
    setEstaEditando(false);
  };

  const handleDeleteProducto = async () => {
    if (productoSeleccionado && productoSeleccionado.idServiceProduct) {
      try {
        await deleteItem('serviceProducts', productoSeleccionado.idServiceProduct);
        handleDeselectProducto();
      } catch (error) {
        console.error("Error al eliminar el producto: ", error);
      }
    }
  };

  useEffect(() => {
    //fetchProductos();
    //fetchComisiones();
  }, []);

  const handleDeleteProduct = async (id: number) => {
    try {
      await deleteItem('serviceProducts', id);
      fetchProductos();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  return (
    <Box p={2} className="agregarOperarioContainer" sx={{ boxShadow: 'none' }}>
      <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
          <AgregarProductoIzquierda
            producto={productoSeleccionado}
            estaEditando={estaEditando}
            onCancelar={handleDeselectProducto}
            productosCopia={productos}
            comisiones={comisiones}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AgregarProductoDerecha
            onProductoSeleccionado={handleSelectProducto}
            productoSeleccionado={productoSeleccionado}
          />
          {productoSeleccionado && (
            <Box display="flex" justifyContent="space-around" marginY={2}>
              <Button variant="contained" color="error" onClick={handleDeleteProducto}>Eliminar</Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgregarProducto;
