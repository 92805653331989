import axios from 'axios';
import { ComisionInterface } from '../interfaces/models'

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
});


export const comisionesCreate = async (data: ComisionInterface) => {
    try {
        const response = await api.post('/comisionCrear', data);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
        //throw new Error(error.response?.data || "Error creating Producto");
    }
};

export const comisionesReadAll = async (idSalon = 0) => {
    try {
        const response = await api.get(`/comisionReadAll?idSalon=${idSalon}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};

export const comisionesDelete = async (id: number) => {
    try {
        // or await axios.delete(`${baseURL}/servicesProductsDelete?idServiceProduct=${id}`);
        const response = await api.delete(`/comisionDelete`, {
            params: {
                idComision: id
            }
        });
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};
