import React, { useContext } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from '@mui/material';
import InventoryContext from '../../../../contexts/InventoryContext';

interface ScrollableBoxProps {
    children: React.ReactNode; // Puedes usar ReactNode para tipos de hijos en React
}

const ScrollableBox: React.FC<ScrollableBoxProps> = ({ children }) => (
  <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
      {children}
  </div>
);

interface SaleDetail {
  idSales: number;
  productName: string;
  productType: string;
  saleDate: Date;
  cost: number;
  quantity: number;
  payment: boolean;
  comision: number;
}

interface CombinedUserData {
  idUser: number;
  firstName: string;
  lastName: string;
  totalServices: number;
  totalProducts: number;
  totalServicesValue: number;
  totalProductsValue: number;
  salesDetails: SaleDetail[];
  comision: number;
  ci: number;
  celular: number;
  sueldo: number;
  diasTrabajados: number;
  fechaInicio: Date;
  totalAPagar: number;
  sueldoOriginal: number;
  diasTrabajarOriginal: number;
  valorAdelantos: number;
}

interface IzquierdoSueldosProps {
  combinedData: CombinedUserData[];
  searchTerm: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRowClick: (userId: number, currentUserTemporal: CombinedUserData) => void;
  selectedUser: number | null;
}

const IzquierdoSueldos: React.FC<IzquierdoSueldosProps> = ({
  combinedData, searchTerm, handleSearchChange, handleRowClick, selectedUser
}) => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('UserList must be used within an InventoryProvider');
  }
  const { tableData, deleteItem, addItem, updateItem } = context;

  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <Box sx={{ p: 2 }}>
        <TextField
          id="search-detail"
          label="Buscar personal"
          variant="outlined"
          size="small"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Box>
      <ScrollableBox>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>NOMBRE</Typography></TableCell>
                <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>SERV</Typography></TableCell>
                <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>COM S</Typography></TableCell>
                <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>VENT</Typography></TableCell>
                <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>COM V</Typography></TableCell>
                <TableCell><Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>TOTAL</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {combinedData.filter(user => user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) || user.lastName.toLowerCase().includes(searchTerm.toLowerCase())).map((user) => (
                <TableRow
                  key={user.idUser}
                  onClick={() => handleRowClick(user.idUser, user)}
                  sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }, backgroundColor: selectedUser === user.idUser ? 'rgba(0, 0, 0, 0.08)' : 'transparent' }}
                >
                  <TableCell>{user.firstName} {user.lastName}</TableCell>
                  <TableCell>{user.totalServices}</TableCell>
                  <TableCell>{user.totalServicesValue.toFixed(2)}</TableCell>
                  <TableCell>{user.totalProducts}</TableCell>
                  <TableCell>{user.totalProductsValue.toFixed(2)}</TableCell>
                  <TableCell>{(user.totalServicesValue + user.totalProductsValue).toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ScrollableBox>
    </Box>
  );
};

export default IzquierdoSueldos;
