import React from 'react';
import { Box, Typography } from '@mui/material';

interface ImageBannerProps {
  imageUrl: string;
  title?: string;
  subtitle?: string;
  height?: string | number;
  onClick?: () => void;  // La función onClick es opcional
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
  overlayColor?: string;
}

const ImageBanner: React.FC<ImageBannerProps> = ({
  imageUrl,
  title = "",
  subtitle = "",
  height = '80vh',
  onClick,
  titleStyle = {},
  subtitleStyle = {},
  overlayColor = 'rgba(0, 0, 0, 0.5)'
}) => {
  return (
    <Box
      onClick={onClick ? onClick : undefined}  // Verificamos si la función onClick está definida y la llamamos
      sx={{
        height: height,
        width: '100%',  // Asegura que el banner ocupe el 100% del ancho de la pantalla
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        cursor: onClick ? 'pointer' : 'default', // Cambiamos el cursor si hay una función onClick definida
      }}
    >
      {/* Imagen como elemento <img /> */}
      <img
        src={imageUrl}
        alt={title || 'banner image'}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'fill', // Asegura que la imagen se ajuste al contenedor, incluso si se deforma
        }}
      />

      {/* Overlay para oscurecer la imagen si es necesario */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: overlayColor,
          zIndex: 1,
        }}
      />

      {/* Contenido del banner */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          color: '#fff',
          textAlign: 'center',
        }}
      >
        {title && (
          <Typography variant="h2" sx={{ fontWeight: 'bold', ...titleStyle }}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="h4" sx={{ mt: 2, ...subtitleStyle }}>
            {subtitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default ImageBanner;
