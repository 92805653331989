import axios from 'axios';
import { TicketAttributesInterface } from '../interfaces/models'

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
});


export const ticketCreate = async (data: TicketAttributesInterface) => {
    try {
        const response = await api.post('/ticketsCreate', data);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
        //throw new Error(error.response?.data || "Error creating Producto");
    }
};
  
export const ticketDelete = async (id: number) => {
    try {
        const response = await api.delete(`/ticketsDelete?id=${id}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};

export const ticketReadAll = async (idSalon = 0) => {
    try {
        const response = await api.get(`/ticketsReadAll?idSalon=${idSalon}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};
  
export const ticketReadUnique = async (id: number) => {
    try {
        const response = await api.get(`/ticketsReadUnique?id=${id}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};

export const ticketUpdate = async (data: TicketAttributesInterface) => {
    try {
        const response = await api.post('/ticketsUpdate', data);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};

