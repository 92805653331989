import axios from 'axios';
import { SaleAttributesInterface } from '../interfaces/models'

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
});


export const saleCreate = async (data: SaleAttributesInterface) => {
    try {
        const response = await api.post('/salesCreate', data);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
        //throw new Error(error.response?.data || "Error creating Producto");
    }
};
  
export const saleDelete = async (id: number) => {
    try {
        const response = await api.delete(`/salesDelete?id=${id}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};

export const saleReadAll = async (idSalon = 0) => {
    try {
        const response = await api.get(`/salesReadAll?idSalon=${idSalon}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};
  
export const saleReadUnique = async (id: number) => {
    try {
        const response = await api.get(`/salesReadUnique?id=${id}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};

export const saleUpdate = async (id: number, data?: SaleAttributesInterface) => {
    try {
        const response = await api.put(`/salesUpdate?idSales=${id}`, { payment: true });
        //console.log("--response iupdate: ",response)
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};

