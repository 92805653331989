import axios from 'axios';
import { UserAttributes } from '../interfaces/models'

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
});


export const usuariosCreate = async (data: UserAttributes) => {
    try {
        const response = await api.post('/usersCreate', data);
        console.log("--------- response is: ",response.data)
        return response.data;
    } catch (error) {
        console.log("error : ", error);
        return null;
        //throw new Error(error.response?.data || "Error creating Producto");
    }
};
  
export const usuariosDelete = async (id: number) => {
    try {
        const response = await api.delete(`/usersDelete?id=${id}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};

export const usuariosReadAll = async (idSalon = 0) => {
    try {
        const response = await api.get(`/usersReadAll?idSalon=${idSalon}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
        return null
    }
};
  
export const usuariosReadUnique = async (id: number) => {
    try {
        const response = await api.get(`/usersReadUnique?id=${id}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};

export const usuariosUpdate = async (data: UserAttributes) => {
    try {
        const response = await api.post('/userUpdate', data);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};

