import axios from 'axios';
import { AsistenciaAttributes } from '../interfaces/models'

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
});


export const asistenciaCreate = async (data: AsistenciaAttributes) => {
    try {
        const response = await api.post('/asistenciaCreate', data);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
        return null;
        //throw new Error(error.response?.data || "Error creating Producto");
    }
};


export const asistenciaReadAll = async (idSalon = 0) => {
    try {
        const response = await api.get(`/asistenciaReadAll?idSalon=${idSalon}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error);
        return null;
    }
};

export const asistenciaReadAllActive = async (idSalon = 0) => {
    try {
        const response = await api.get(`/asistenciaReadAllActive?idSalon=${idSalon}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error);
        return null;
    }
};

export const asistenciaReadUnique = async (id: number): Promise<AsistenciaAttributes[] | null> => {
    try {
        const response = await api.get(`/asistenciaReadId?idUser=${id}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error);
        return null;  // Devolver null en caso de error
    }
};


export const asistenciaUpdatePagado = async (idAsistencia: number) => {
    try {
        const response = await api.patch('/asistenciaUpdatePagado', { idAsistencia });
        console.log("response",response)
        return response.data;
    } catch (error) {
        console.log("error : ", error);
        return null;
    }
};


export const asistenciaUpdateSalida = async (data: AsistenciaAttributes) => {
    try {
        const response = await api.patch('/asistenciaUpdateSalida', data);
        return response.data;
    } catch (error) {
        console.log("error : ", error);
        return null;
    }
};

