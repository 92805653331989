import axios from 'axios';
import { ServiceProductAttributesInterface } from '../interfaces/models'

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
});


export const serviceProductCreate = async (data: ServiceProductAttributesInterface) => {
    try {
        const response = await api.post('/servicesProductsCreate', data);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
        //throw new Error(error.response?.data || "Error creating Producto");
    }
};
  
export const serviceProductDelete = async (id: number) => {
    try {
        // or await axios.delete(`${baseURL}/servicesProductsDelete?idServiceProduct=${id}`);
        const response = await api.delete(`/servicesProductsDelete`, {
            params: {
              idServiceProduct: id
            }
        });
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};

export const serviceProductReadAll = async (idSalon = 0) => {
    try {
        const response = await api.get(`/servicesProductsReadAll?idSalon=${idSalon}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};
  
export const serviceProductReadUnique = async (id: number) => {
    try {
        const response = await api.get(`/servicesProductsReadUnique?id=${id}`);
        return response.data;
    } catch (error) {
        console.log("error : ", error)
    }
};
// TODO
export const serviceProductUpdate = async (data: ServiceProductAttributesInterface) => {
    try {
        const response = await api.put(`/servicesProductsUpdate?idServiceProduct=${data.idServiceProduct}`, data);
        return response.data;
    } catch (error) {
        console.log("error : ", error);
        return null;
    }
};
