import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="logo">
        <Link to="/"> {/* Logo que enlaza a la página de inicio */}
          <img src="/images/logo_nova_2_fuego.png" alt="Logo" className="logo-image" />
        </Link>
      </div>
      <nav>
        <ul className="nav-links">
          {/*<li><Link to="/#services">Servicios</Link></li>
          <li><Link to="/#plans">Planes</Link></li>
          <li><Link to="/#about-us">Nosotros</Link></li>*/}
          <li><Link to="/login">Acceso</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
