// src/pages/login/authUtils.ts
import { jwtDecode } from 'jwt-decode'; // Importa jwtDecode de la forma correcta

interface DecodedToken {
  idSalon: number;
  rol: number;
  iat: number;
  exp: number;
}

export const authenticateUser = async (username: string, password: string): Promise<string | null> => {
  try {
    const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
    console.log("Sending authentication request to:", `${backendEndpoint}/verifyCuenta`);
    const response = await fetch(`${backendEndpoint}/verifyCuenta`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ cuenta: username, password: password })
    });

    console.log("Authentication response status:", response.status);
    if (response.ok) {
      const data = await response.json();
      console.log("Authentication response data:", data);
      return data.token;
    } else {
      console.error("Authentication failed with status:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Authentication error:", error);
    return null;
  }
};

interface DecodedToken {
  idSalon: number;
  rol: number;
  iat: number;
  exp: number;
  idUsuario: number;
}

export const getDetailsFromToken = (token: string): { idSalon: number; rol: number, idUsuario: number } => {
  console.log("my otken is: ", token)
  const decodedToken: DecodedToken = jwtDecode(token);
  console.log("Decoded token is: ", decodedToken);
  return { idSalon: decodedToken.idSalon, rol: decodedToken.rol, idUsuario: decodedToken.idUsuario };
};
