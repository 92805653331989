import React, { useState, useMemo, useEffect, useContext } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Box, Typography,
  Button, Tabs, Tab
} from '@mui/material';
import { AsistenciaAttributes, PersonaDatosMezclados, SaleAttributesInterface } from '../../../interfaces/models';
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { makeStyles } from '@mui/styles';
import InventoryContext from '../../../contexts/InventoryContext';

const useStyles = makeStyles({
  customLabel: {
    '&.MuiInputLabel-shrink': {
      transform: 'translate(20px, -6px) scale(0.75)',
    }
  },
  card: {
    backgroundColor: '#002855',
    color: 'white',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
    textAlign: 'center',
    minWidth: '150px',
    maxWidth: '200px',
  },
  highlightedCard: {
    backgroundColor: '#002855',
    color: 'white',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
    textAlign: 'center',
    minWidth: '150px',
    maxWidth: '200px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add some shadow for better look
    marginTop: '20px', // Add some space at the top
  },
  customColorCard: {
    backgroundColor: '#8884D8', // Color específico
    color: 'white',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
    textAlign: 'center',
    minWidth: '150px',
    maxWidth: '200px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    gap: '20px',
  }
});
const createDynamicStyles = (color: string) => ({
  backgroundColor: color,
  color: 'white',
  padding: '20px',
  borderRadius: '8px',
  marginBottom: '20px',
  textAlign: 'center',
  minWidth: '150px',
  maxWidth: '200px',
});
interface MyComponentProps {
  title: string;
  content: string;
  color: string;
}

const MyComponent: React.FC<MyComponentProps> = ({ title, content, color }) => {
  const classes = useStyles();
  const dynamicStyles = createDynamicStyles(color);

  return (
    <Box sx={dynamicStyles}>
      <Typography variant="h6">{content}</Typography>
      <Typography variant="subtitle2">{title}</Typography>
    </Box>
  );
};

const calcularFechasPeriodo = (asistencias: AsistenciaAttributes[]) => {
  const fechas = asistencias.map(a => new Date(a.createdAt!));
  fechas.sort((a, b) => a.getTime() - b.getTime());
  const fechaInicio = fechas.length > 0 ? fechas[0].toISOString().split('T')[0] : '';
  const fechaFin = fechas.length > 0 ? fechas[fechas.length - 1].toISOString().split('T')[0] : '';
  return { fechaInicio, fechaFin };
};

const calcularTotalSueldo = (asistenciasPeriodo: AsistenciaAttributes[], salario: number, diasTrabajar: number) => {
  const diasTrabajados = asistenciasPeriodo.length;
  return (diasTrabajados / diasTrabajar) * salario;
};

interface Props {
  datos: PersonaDatosMezclados[];
}

const ComponenteDatosPersonas: React.FC<Props> = ({ datos }) => {

  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('UserList must be used within an InventoryProvider');
  }
  const { tableData, deleteItem, addItem, updateItem } = context;



  const [filtro, setFiltro] = useState('');
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState<PersonaDatosMezclados | null>(null);
  const [selectedAsistencias, setSelectedAsistencias] = useState<AsistenciaAttributes[]>([]);
  const [ingreso, setIngreso] = useState('');
  const [totalDiasTrabajados, setTotalDiasTrabajados] = useState(0);
  const [mostrarObservaciones, setMostrarObservaciones] = useState(true);
  const [mostrarVentas, setMostrarVentas] = useState(true);
  const [periodoActual, setPeriodoActual] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const classes = useStyles();

  const datosFiltrados = useMemo(() => {
    let resultado;

    if (usuarioSeleccionado) {
      resultado = [usuarioSeleccionado];
    } else {
      resultado = datos.filter((persona) =>
        persona.firstName.toLowerCase().includes(filtro.toLowerCase()) ||
        persona.lastName.toLowerCase().includes(filtro.toLowerCase())
      );

      resultado.sort((a, b) => a.firstName.localeCompare(b.firstName));
    }

    return resultado;
  }, [datos, filtro, usuarioSeleccionado]);

  useEffect(() => {
    if (usuarioSeleccionado && usuarioSeleccionado.asistencias) {
      const fechasValidas = usuarioSeleccionado.asistencias
        .map(asistencia => new Date(asistencia.createdAt!));

      fechasValidas.sort((a, b) => a.getTime() - b.getTime());

      if (fechasValidas.length > 0) {
        setIngreso(fechasValidas[0].toISOString().split('T')[0]);
      }

      const total = fechasValidas.length;
      setTotalDiasTrabajados(total);
    }
  }, [usuarioSeleccionado]);

  const seleccionarUsuario = (persona: PersonaDatosMezclados) => {
    if (usuarioSeleccionado && usuarioSeleccionado.idUser === persona.idUser) {
      setUsuarioSeleccionado(null);
    } else {
      setUsuarioSeleccionado(persona);
      setPeriodoActual(0); // Resetear el periodo actual cuando se selecciona un nuevo usuario
    }
  };

  const datosGraficoConsolidado = useMemo(() => {
    let datosConsolidados: { periodo: string; productos: number; servicios: number; salario: number; diasTrabajar: number; totalVentas: number; totalComision: number; }[] = [];

    if (usuarioSeleccionado) {
      const fechas = Object.keys(usuarioSeleccionado.ventasAgrupadas || {}).sort((a, b) => new Date(b).getTime() - new Date(a).getTime()); // Ordenar fechas de más reciente a más antiguo
      fechas.forEach((fecha, index) => {
        const totalProductos = usuarioSeleccionado.productosServiciosPorFecha?.[fecha]?.productos || 0;
        const totalServicios = usuarioSeleccionado.productosServiciosPorFecha?.[fecha]?.servicios || 0;
        const totalVentas = (usuarioSeleccionado.ventasAgrupadas?.[fecha] || []).reduce((sum, venta) => sum + (venta.quantity || 0) * (venta.valorProductoIndividual || 0), 0);
        const totalComision = (usuarioSeleccionado.ventasAgrupadas?.[fecha] || []).reduce((sum, venta) => sum + (venta.valorComisionAPagar || 0), 0);
        datosConsolidados.push({
          periodo: `Periodo ${index + 1}`,
          productos: totalProductos,
          servicios: totalServicios,
          salario: usuarioSeleccionado.salario,
          diasTrabajar: usuarioSeleccionado.diasTrabajar,
          totalVentas: totalVentas,
          totalComision: totalComision,
        });
      });
    }

    return datosConsolidados;
  }, [usuarioSeleccionado]);

  const periodo = datosGraficoConsolidado[periodoActual];
  const { fechaInicio, fechaFin } = calcularFechasPeriodo(usuarioSeleccionado?.asistenciasAgrupadas?.[Object.keys(usuarioSeleccionado.ventasAgrupadas || {})[periodoActual]] || []);
  const asistenciasPeriodo = usuarioSeleccionado?.asistenciasAgrupadas?.[Object.keys(usuarioSeleccionado.ventasAgrupadas || {})[periodoActual]] || [];
  const totalSueldo = calcularTotalSueldo(asistenciasPeriodo, periodo?.salario, periodo?.diasTrabajar);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const [expandedObservation, setExpandedObservation] = useState<number | null>(null);


  const toggleObservation = (index: number) => {
    setExpandedObservation(expandedObservation === index ? null : index);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'white' }}>
      <TextField
        fullWidth
        label="Buscar"
        variant="outlined"
        InputLabelProps={{
          classes: {
            root: classes.customLabel
          }
        }}
        onChange={(e) => setFiltro(e.target.value)}
      />

      <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto', bgcolor: 'white' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Nombre Completo</TableCell>
              <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>CI</TableCell>
              <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Celular</TableCell>
              <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Sueldo</TableCell>
              <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Rango Días</TableCell>
              <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Comisiones</TableCell>
              <TableCell style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Caja</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datosFiltrados.map((persona) => (
              <TableRow
                key={persona.idUser}
                hover
                onClick={() => seleccionarUsuario(persona)}
                selected={usuarioSeleccionado ? usuarioSeleccionado.idUser === persona.idUser : false}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{`${persona.firstName} ${persona.lastName}`}</TableCell>
                <TableCell>{persona.idCard}</TableCell>
                <TableCell>{persona.mobileNumber}</TableCell>
                <TableCell>{persona.salario}</TableCell>
                <TableCell>{persona.diasTrabajar}</TableCell>
                <TableCell>{persona.comision ? 'Sí' : 'No'}</TableCell>
                <TableCell>{persona.role === 1 ? 'Sí' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {usuarioSeleccionado && (
        <Box sx={{ mt: 2, display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}>
          <Box sx={{ flex: 1 }}>
          <Typography 
            variant="h6" 
            align="center" 
            sx={{ 
              mt: 2, 
              mb: 2, 
              textTransform: "uppercase", 
              letterSpacing: "2px", 
              fontWeight: "bold"
            }}
          >
            Resumen de Ventas por Periodo
          </Typography>

            <ResponsiveContainer width="100%" height={250}>
              <BarChart data={datosGraficoConsolidado}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="periodo" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="productos" fill="#8884d8" name="Productos" />
                <Bar dataKey="servicios" fill="#82ca9d" name="Servicios" />
              </BarChart>
            </ResponsiveContainer>
            <Box className={classes.container}>

              {/*FECHA DE INICIO*/}
              <Box
                sx={{
                  border: '2px solid #002855',
                  borderRadius: '8px',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  textAlign: 'center',
                  width: 'fit-content',
                  margin: 'auto', // To center the box horizontally
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: '#002855',
                    fontWeight: 'bold',
                  }}
                >
                  {fechaInicio}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#002855',
                  }}
                >
                  Fecha de inicio
                </Typography>
              </Box>

              {/*FECHA DE INICIO*/}

              <Box
                sx={{
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'transparent', // No border, transparent background
                  textAlign: 'center',
                  width: 'fit-content',
                  margin: 'auto', // To center the box horizontally if needed
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: '#002855',
                    fontWeight: 'bold',
                  }}
                >
                  {asistenciasPeriodo.length}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#002855',
                  }}
                >
                  Días Trabajados
                </Typography>
              </Box>
              {/*FECHA DE FIN*/}
              <Box
                sx={{
                  border: '2px solid #002855',
                  borderRadius: '8px',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  textAlign: 'center',
                  width: 'fit-content',
                  margin: 'auto',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: '#002855',
                    fontWeight: 'bold',
                  }}
                >
                  {fechaFin}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#002855',
                  }}
                >
                  Fecha Fin
                </Typography>
              </Box>
              {/*PRODUCTOS VENDIDOS*/}
              <MyComponent title="Productos Vendidos" content={periodo.productos.toString()} color="#8884D8" />
              <MyComponent title="Servicios Realizados" content={periodo.servicios.toString()} color="#82CA9D" />
              {/*<Box className={classes.card}>
                <Typography variant="h6">{periodo.diasTrabajar}</Typography>
                <Typography variant="subtitle2">Días Trabajados</Typography>
              </Box>*/}
              <MyComponent title="Comisión Total" content={periodo.totalComision.toFixed(2)} color="#002855" />
              <MyComponent title="Sueldo" content={totalSueldo.toFixed(2)} color="#002855" />
              <MyComponent title="Utilidad Total" content={(periodo.totalVentas - periodo.totalComision).toFixed(2)} color="#F59622" />
              <MyComponent title="Sueldo Acumulado" content={(totalSueldo + periodo.totalComision).toFixed(2)} color="#F59622" />
            </Box>

          </Box>
          <Box sx={{ flex: 1, pl: { lg: 2 } }}>
          <Typography variant="h6" sx={{ color: '#F59622', fontWeight: 'bold', textAlign: 'center' }}>
        REPORTE GENERAL POR FRECUENCIA DE PAGO
      </Typography>   
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Button variant="contained" disabled={periodoActual === 0} onClick={() => setPeriodoActual(periodoActual - 1)}>
                Anterior
              </Button>
              <Typography variant="h6">{`P${periodoActual + 1}`}</Typography>
              <Button variant="contained" disabled={periodoActual === datosGraficoConsolidado.length - 1} onClick={() => setPeriodoActual(periodoActual + 1)}>
                Siguiente
              </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
         <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Asistencias" />
            <Tab label="Productos Vendidos" />
          </Tabs>
              {tabIndex === 0 && (
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                  <TableRow>
                    <TableCell sx={{ textTransform: "uppercase", fontWeight: "bold", textAlign: "center", letterSpacing: "1px" }}>
                      Fecha
                    </TableCell>
                    <TableCell sx={{ textTransform: "uppercase", fontWeight: "bold", textAlign: "center", letterSpacing: "1px" }}>
                      Hora de entrada
                    </TableCell>
                    <TableCell sx={{ textTransform: "uppercase", fontWeight: "bold", textAlign: "center", letterSpacing: "1px" }}>
                      Adelanto
                    </TableCell>
                    <TableCell sx={{ textTransform: "uppercase", fontWeight: "bold", textAlign: "center", letterSpacing: "1px" }}>
                      Observaciones
                    </TableCell>
                  </TableRow>

                  </TableHead>
                  <TableBody>
                    {asistenciasPeriodo.map((asistencia, index) => (
                      <TableRow key={index}>
                        <TableCell>{new Date(asistencia.createdAt!).toLocaleDateString()}</TableCell>
                        <TableCell>{asistencia.createdAt ? new Date(asistencia.createdAt).toLocaleTimeString() : 'N/A'}</TableCell>
                        <TableCell>
                          <Box sx={{ bgcolor: asistencia.adelanto ? '#FFD700' : 'transparent', p: 1, borderRadius: '4px' }}>
                            {asistencia.valorAdelanto ? asistencia.valorAdelanto : 'N/A'}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography 
                            sx={{ cursor: 'pointer', color: 'blue' }} 
                            onClick={() => toggleObservation(index)}
                          >
                            {expandedObservation === index ? asistencia.notas : asistencia.notas?.substring(0, 20)}
                            {asistencia.notas && asistencia.notas.length > 20 && '...'}
                          </Typography>
                          {expandedObservation === index && (
                            <Typography>{asistencia.notas}</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
{tabIndex === 1 && (
  <Box>
    <Typography variant="h6">Productos Vendidos</Typography>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell>Cantidad</TableCell>
            <TableCell>Comisión</TableCell>
            <TableCell>Utilidad</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usuarioSeleccionado.ventasAgrupadas?.[Object.keys(usuarioSeleccionado.ventasAgrupadas || {})[periodoActual]] && Object.entries(
            usuarioSeleccionado.ventasAgrupadas?.[Object.keys(usuarioSeleccionado.ventasAgrupadas || {})[periodoActual]].reduce((acc, venta) => {
              const fecha = new Date(venta.createdAt!).toLocaleDateString();
              if (!acc[fecha]) acc[fecha] = [];
              acc[fecha].push(venta);
              return acc;
            }, {} as { [key: string]: SaleAttributesInterface[] })
          ).map(([fecha, ventas]) => (
            ventas.map((venta, idx) => {
              const producto = tableData.serviceProducts.find(p => p.idServiceProduct === venta.idServiceProduct);
              const nombreProducto = producto ? producto.detalles : 'Producto desconocido';
              return (
                <TableRow key={idx}>
                  {idx === 0 && <TableCell rowSpan={ventas.length}>{fecha}</TableCell>}
                  <TableCell>{nombreProducto}</TableCell>
                  <TableCell>{venta.quantity}</TableCell>
                  <TableCell>{venta.valorProductoIndividual}</TableCell>
                  <TableCell>{(venta.quantity! * venta.valorProductoIndividual!).toFixed(2)}</TableCell>
                </TableRow>
              );
            })
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
)}



            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ComponenteDatosPersonas;
