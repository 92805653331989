// SueldosOperario.tsx
import React, {useState, useEffect, ReactNode, useContext} from 'react';
import {
  Box,
  Paper,
  Grid,
  Alert
} from '@mui/material';
import './SueldosOperario.css'; // Make sure to include custom styles if needed
import jsPDF from 'jspdf';

import IzquierdoSueldos from './IzquierdoSueldos';
import DerechoSueldos from './DerechosSueldos';
import InventoryContext from '../../../../contexts/InventoryContext';
import { UserAttributes, SaleAttributesInterface, ServiceProductAttributesInterface, AsistenciaAttributes } from '../../../../interfaces/models'

interface SaleDetail {
  idSales: number;
  productName: string;
  productType: string; // Asumiendo que este campo refleja si es un servicio o un producto
  saleDate: Date;
  cost: number; // Costo total del producto (salesPrice * quantity)
  quantity: number; // Cantidad vendida
  payment: boolean;
  comision: number;
}

interface CombinedUserData {
  idUser: number;
  firstName: string;
  lastName: string;
  totalServices: number; // Cantidad total de servicios vendidos
  totalProducts: number; // Cantidad total de productos vendidos
  totalServicesValue: number; // Costo total de los servicios vendidos
  totalProductsValue: number; // Costo total de los productos vendidos
  salesDetails: SaleDetail[]; // Nuevo campo para detalles de ventas
  comision: number;
  ci: number;
  celular: number;
  sueldo: number;
  diasTrabajados: number;
  fechaInicio: Date;
  totalAPagar: number;
  sueldoOriginal: number;
  diasTrabajarOriginal: number;
  valorAdelantos: number;
}

interface ScrollableBoxProps {
  children: ReactNode; // Puedes usar ReactNode para tipos de hijos en React
}
const ScrollableBox: React.FC<ScrollableBoxProps> = ({ children }) => (
  <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
    {children}
  </div>
);

type Severity = 'info' | 'success' | 'error';
const SueldosOperario = () => {
  const context = useContext(InventoryContext);

  if (!context) {
    throw new Error('UserList must be used within an InventoryProvider');
  }
  const { tableData, deleteItem, addItem, updateItem } = context;

  const [combinedData, setCombinedData] = useState<CombinedUserData[]>([]);
  const [selectedUser, setSelectedUser] = useState<number | null>(null); // Nuevo estado para manejar el usuario seleccionado
  const validSeverity = (severity:string) => ['info', 'success', 'error'].includes(severity) ? severity : 'info';
  //const [salaryData, setSalaryData] = useState();
  const [alert, setAlert] = React.useState({ show: false, message: '', severity: 'info' } as { show: boolean, message: string, severity: Severity });  
  const [currentUser, setCurrentUser] = useState<CombinedUserData | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [asistenciasPorUsuarioGuardadas, setAsistenciasPorUsuarioGuardadas] = useState<Map<number, AsistenciaAttributes[]>>(new Map());
  
  // Función para obtener la lista combinada:
  function getCombinedUserData(
    users: UserAttributes[], 
    sales: SaleAttributesInterface[], 
    servicesProducts: ServiceProductAttributesInterface[],
    asistenciasPorUsuario: Map<number, AsistenciaAttributes[]>
    ): CombinedUserData[] {
    return users.map(user => {
      const userSales = sales.filter(sale => sale.idUser === user.idUser);
      let totalServices = 0;
      let totalProducts = 0;
      let totalServicesValue = 0;
      let totalProductsValue = 0;
      let salesDetails: SaleDetail[] = []; // Inicializa el vector de detalles de ventas
      let comision = 0;
      const ci = Number(user.idCard);
      const celular = Number(user.mobileNumber);
      
      const asistenciasDeUsuario = asistenciasPorUsuario.get(user.idUser!) ?? [];
      //console.log("---------------- user id: ", user.idUser, asistenciasDeUsuario)
      const { totalAsistencias, totalAdelantos } = contarDiasConAsistenciasNoPagadas(asistenciasDeUsuario);

      let sueldo = 0
      if(user.diasTrabajar! > 0) {
        sueldo = user.salario!/user.diasTrabajar!*totalAsistencias;
      }
      
      
      const fechaInicio = new Date(user.createdAt!);
      userSales.forEach(sale => {
        if (!sale.payment) {
          const serviceProduct = servicesProducts.find(sp => sp.idServiceProduct === sale.idServiceProduct);
          if (serviceProduct) {
            //console.log("*-*-*-*-servicio: ",serviceProduct)
            // Distinguir entre servicio y producto
            const isService = serviceProduct.type === 'servicio'; // Asumiendo que existe un campo 'type'
            const quantity = sale.quantity!;
            const value = serviceProduct.salesPrice! * quantity;

            const cost = serviceProduct.salesPrice! * sale.quantity!; // Calcula el costo
            switch(sale.tipoComision) {
              case 1:
                comision = sale.cantidadPago!*cost/100;
                break;
              case 0:
                comision = sale.cantidadPago!*sale.quantity!;
                break;
              case 3:
                comision = 0;
                break;
            }
            if (isService) {
              totalServices += quantity;
              totalServicesValue += comision
            } else {
              totalProducts += quantity;
              totalProductsValue += comision;
            }
            //console.log("-----------",{serviceProduct})
            // Agregar el detalle de la venta al vector
            salesDetails.push({
              idSales: sale.idSales!,
              productName: serviceProduct.detalles!,
              productType: serviceProduct.type!, // Asume que 'type' indica si es servicio o producto
              saleDate: new Date(sale.createdAt!), // Asegura que sea un objeto Date
              cost: cost, // Costo total del producto vendido
              quantity: sale.quantity!, // Cantidad vendida
              payment: false,
              comision: comision
            });
          }
        }

      });
  
      return {
        idUser: user.idUser!,
        firstName: user.firstName!,
        lastName: user.lastName!,
        totalServices,
        totalProducts,
        totalServicesValue,
        totalProductsValue,
        salesDetails, // Incluir los detalles de ventas en el resultado
        comision: totalServicesValue+totalProductsValue,
        ci,
        celular,
        sueldo,
        diasTrabajados: totalAsistencias,
        fechaInicio,
        diasTrabajar:user.diasTrabajar!,
        totalAPagar: totalServicesValue+totalProductsValue+sueldo-totalAdelantos,
        sueldoOriginal: user.salario!,
        diasTrabajarOriginal: user.diasTrabajar!,
        valorAdelantos: totalAdelantos,
      };
    });
  }
  


  function contarDiasConAsistenciasNoPagadas(asistencias: AsistenciaAttributes[]) {
    try {
      const asistenciasNoPagadas = asistencias.filter(asistencia => asistencia.pagado === false);
      console.log(" --------- > asistenciasNoPagadas : ", asistenciasNoPagadas)
      const totalAdelantos = asistenciasNoPagadas.reduce((total, asistencia) => {
        return total + (asistencia.valorAdelanto || 0);
      }, 0);
  
      return {
        totalAsistencias: asistenciasNoPagadas.length,
        totalAdelantos: totalAdelantos
      };
    } catch (error) {
      console.log("error en contarDiasConAsistenciasNoPagadas: ", error);
      return {
        totalAsistencias: 0,
        totalAdelantos: 0
      };
    }
  }
  
  
  const fetchAsistenciasPorUsuario = async (users : UserAttributes[]): Promise<Map<number, AsistenciaAttributes[]>>  => {
    
    try {
      // Crear un Map para guardar las asistencias por idUser
      const mapAsistenciasPorUsuario = new Map<number, AsistenciaAttributes[]>();

      users.forEach(user => {
        const asistenciasUsuario = tableData.asistencias.filter(asistencia => asistencia.idUser === user.idUser);
        mapAsistenciasPorUsuario.set(user.idUser!, asistenciasUsuario);
      });

      return mapAsistenciasPorUsuario;

    } catch (error) {
      console.error('Error al cargar las asistencias:', error);
      return new Map(); // Retornar un Map vacío en caso de error
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        // Realiza las tres llamadas a la API simultáneamente
        const usersResponse = tableData.usuarios// await usuariosReadAll();
        // Extrae los datos de cada respuesta
        const users = usersResponse;
        const sales = tableData.sales; // await saleReadAll();
        const serviceProducts = tableData.serviceProducts //await serviceProductReadAll();
        const asistenciasPorUsuario = await fetchAsistenciasPorUsuario(users);
        //console.log("users data: ",users);
        //console.log("users sales: ",sales);
        //console.log("users serviceProducts: ",serviceProducts);
        //console.log("users asistenciasPorUsuario: ",asistenciasPorUsuario);
        
        // Aquí implementarás la lógica para unir y procesar los datos
        setAsistenciasPorUsuarioGuardadas(asistenciasPorUsuario);
        const resultCombineData = getCombinedUserData(users, sales, serviceProducts,asistenciasPorUsuario);
        setCombinedData(resultCombineData);
        //console.log("** combine data:",resultCombineData)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Función para manejar cambios en el término de búsqueda
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Filtrar los datos combinados basados en el término de búsqueda
  const filteredData = combinedData.filter(user =>
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );



  const handlePayment = async () => {
    const selectedUserData2 = combinedData.find(user => user.idUser === selectedUser);
    console.log({selectedUserData2,asistenciasPorUsuarioGuardadas})
    const confirmPayment = window.confirm("¿Estás seguro de que quieres procesar el pago?");

    if (selectedUser && confirmPayment) {
      const selectedUserData = combinedData.find(user => user.idUser === selectedUser);
      if (selectedUserData && selectedUserData.salesDetails.length > 0) {
        try {
          setAlert({ show: true, message: 'Procesando pagos...', severity: 'info' });

          // Enviar solicitudes PUT para cada idSales no pagado
          await Promise.all(
            selectedUserData.salesDetails.map(detail => 
              updateItem('sales', detail.idSales,{})//saleUpdate(detail.idSales)
            )
          );
          const filteredAsistencias = Array.from(asistenciasPorUsuarioGuardadas.entries())
            .filter(([userId, asistencias]) => userId === selectedUser)
            .flatMap(([, asistencias]) => asistencias);

          await Promise.all(
            filteredAsistencias.map(asistencia =>
              updateItem('asistencias_pagado', asistencia.idAsistencia!, {})
            )
          );
        
          // Filtrar solo los detalles de ventas que han sido marcados como pagados
          const successfulSalesDetails = selectedUserData.salesDetails.filter(detail => detail.payment);

          // Limpia los detalles de ventas para el usuario seleccionado
          const updatedCombinedData = combinedData.map(user => {
            if (user.idUser === selectedUser) {
              return {
                ...user,
                salesDetails: [], // Limpia los detalles de ventas ya que todos han sido pagados\
                diasTrabajados: 0 // Limpia los días trabajados
              };
            }
            return user;
          });

          setCombinedData(updatedCombinedData); // Actualizar el estado global

          // Generar y descargar PDF solo con registros exitosos
          const doc = new jsPDF();
          doc.setFontSize(16);
          doc.text('Registros de Ventas Exitosas', 20, 20);

          let y = 30; // Iniciar en la posición y después del título

          selectedUserData.salesDetails.forEach((detail, index) => {
            doc.setFontSize(12);
            doc.text(`ID Venta: ${detail.idSales}`, 20, y);
            y += 10;
            doc.text(`Producto: ${detail.productName}`, 20, y);
            y += 10;
            doc.text(`Cantidad: ${detail.quantity}`, 20, y);
            y += 10;
            doc.text(`Costo Total: $${detail.cost.toFixed(2)}`, 20, y);
            y += 10;
            doc.text(`Fecha de Venta: ${new Date(detail.saleDate).toLocaleDateString()}`, 20, y);
            y += 20; // Agregar espacio antes de la próxima entrada
          });

          // Intentar la descarga automática
          const filename = `ventas_exitosas_${new Date().toLocaleDateString().replace(/\//g, '-')}.pdf`;
          doc.save(filename);
          setSelectedUser(null)
          setAlert({ show: true, message: 'Todos los pagos han sido procesados con éxito. Si tu descarga no comienza automáticamente, por favor usa el siguiente enlace.', severity: 'success' });
          setSelectedUser(null)
      } catch (error) {
          console.error("Error al procesar los pagos:", error);
          setAlert({ show: true, message: 'Error de red al pagar', severity: 'error' });
        }
      } else {
        setAlert({ show: true, message: 'No hay ventas seleccionadas para procesar.', severity: 'info' });
      }
    }
  };


  
  

  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }} className="agregarOperarioContainer">
      <Paper sx={{ width: '100%', margin: 'auto', overflow: 'hidden' }}>
        <Grid container spacing={2}>
          {/* Lado Izquierdo - Listado y búsqueda de trabajadores */}
          <Grid item xs={12} md={6}>
            <IzquierdoSueldos
              combinedData={combinedData}
              searchTerm={searchTerm}
              handleSearchChange={handleSearchChange}
              handleRowClick={(userId, user) => {
                setSelectedUser(userId);  // Selecciona el usuario y actualiza el usuario actual para el lado derecho
                setCurrentUser(user);
              }}
              selectedUser={selectedUser}
            />
          </Grid>
  
          {/* Lado Derecho - Detalles del trabajador seleccionado y acciones */}
          <Grid item xs={12} md={6}>
            <DerechoSueldos
              selectedUser={selectedUser}
              combinedData={combinedData}
              handlePayment={() => {
                if (selectedUser) {
                  handlePayment();  // Elimina el argumento de esta llamada
                }
              }}
            />
          </Grid>
        </Grid>
  
        {/* Alerta para mostrar mensajes como errores o confirmaciones */}
        {alert.show && (
          <Alert severity={alert.severity} style={{ position: 'fixed', bottom: 10, right: 10 }}>
            {alert.message}
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default SueldosOperario;
